import type { Locale } from '@seek/melways-sites';
import { logHandler } from '../utils';
import { useConfig } from './useConfig';

const useEnvironment = () => {
  const { environment } = useConfig();
  logHandler(`Env: ${environment}`);
  return environment;
};

const useLocale = (): Locale => {
  const { locale } = useConfig();
  logHandler(`locale: ${locale}`);
  return locale;
};

export { useLocale, useEnvironment };
