import type { HeadingFragment, TileFragment } from '@seek/cmsu-cms-connect';
import { Stack, Box } from 'braid-design-system';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useScreenSize } from 'src/client/hooks/useScreenSize';
import { PageBlock } from '../../modules';
import type { BraidStackProps } from '../../modules/helpers/types';
import type { Containers } from '../../modules/PageLayout/PageLayout';

import { SaraContainer } from '../../modules/SaraContainer/SaraContainer';
import { getPageProps } from './utils';

const Page = () => {
  const { containers, slug } = useOutletContext<Containers>();
  const viewPoints = useScreenSize();
  const stackSpace: BraidStackProps['space'] = [
    'sara-why-enter',
    'sara-why-enter-nz',
  ].includes(slug)
    ? 'none'
    : 'xlarge';
  return (
    <Box paddingTop="small">
      <Stack space={stackSpace}>
        {containers.map((container, _key) => (
          <SaraContainer key={_key} {...container}>
            {container.blocks.map((block, _index) => (
              <Box key={_index} className="sk-block">
                {block.items.map((item, index) => (
                  <Box key={index} className="sk-block-item">
                    {item.__typename !== 'CHeading' && (
                      <PageBlock
                        containerTile={container.tile as TileFragment}
                        saraBlockHeading={
                          (
                            block.items.find(
                              (x) => x.__typename === 'CHeading',
                            ) as HeadingFragment
                          )?.text
                        }
                        box={block.box}
                        block={item}
                        {...getPageProps(viewPoints, slug)[_key]}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            ))}
          </SaraContainer>
        ))}
      </Stack>
    </Box>
  );
};

export { Page };
