import type { ImageBlockGroupFragment } from '@seek/cmsu-cms-connect';
import { Box, Text, Stack } from 'braid-design-system';
import React from 'react';

import type { ArrayElement } from '../helpers/types';

import * as styles from './ImageBlock.css';

type Props = Pick<
  ArrayElement<ImageBlockGroupFragment['items']>,
  'heading' | 'image'
>;

const ImageBlock = ({ image, heading }: Props) => (
  <Stack space="medium" align="center">
    <Box
      className={styles.image}
      style={{ backgroundImage: `url(${image?.url})` }}
    />
    <Text align="center">{heading?.text}</Text>
  </Stack>
);

export { ImageBlock };
