import type { SiteNamesMatchingClassification } from '@seek/melways-sites';
import type {
  Box,
  Button,
  ButtonLink,
  ContentBlock,
  Heading,
  Stack,
} from 'braid-design-system';

export type AlignmentType = 'left' | 'center' | 'right';

export type NavLinks = Array<{
  name: string;
  link: string;
}>;

export type Site = EmployerSiteName;

export type ArrayElement<ArrayType extends Array<any>> = ArrayType[number];

export enum AlignmentTypeValues {
  Left = 'left',
  Centre = 'center',
  Right = 'right',
}

export type Environment = 'development' | 'production' | 'test';

export type RequireAtLeastOne<T> = {
  [K in keyof T]: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type Zone =
  | 'anz-1'
  | 'anz-2'
  | 'asia-1'
  | 'asia-3'
  | 'asia-4'
  | 'asia-5'
  | 'asia-6'
  | 'asia-7';

export type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;

export type CandidateSiteName = SiteNamesMatchingClassification<{
  product: 'candidate';
}>;

export type BraidBoxProps = React.ComponentProps<typeof Box>;
export type BraidButtonLinkProps = React.ComponentProps<typeof ButtonLink>;
export type BraidHeadingProps = React.ComponentProps<typeof Heading>;
export type BraidStackProps = React.ComponentProps<typeof Stack>;
export type BraidContentBlockProps = React.ComponentProps<typeof ContentBlock>;
export type BraidButtonProps = React.ComponentProps<typeof Button>;
