import React from 'react';

import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';
import { renderers, RichText } from '@seek/cmsu-rich-text';
import { Box, Stack } from 'braid-design-system';
import { HashLink } from 'react-router-hash-link';

import { StyledHeading } from '../StyledHeading/StyledHeading';
import type { ArrayElement } from '../helpers/types';

import * as styles from './Heading.css';
import { SaraButton } from '../SaraButton/SaraButton';

type Props = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
>;

const BorderedTitleNumberedDesc = ({ title, richTextDescription }: Props) => (
  <Stack space="xlarge">
    <Box
      paddingY="small"
      marginTop="medium"
      paddingLeft={'gutter'}
      className={styles.borderLeft}
    >
      {Boolean(title) && (
        <StyledHeading level="1" weight="strong" tone="brand">
          {title}
        </StyledHeading>
      )}
    </Box>

    <RichText
      content={richTextDescription?.raw}
      renderers={{
        ...renderers,
        li: ({ children, index }) => (
          <Box
            className={styles.borderedTitleNumberedDescBorderBottom}
            paddingBottom="large"
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Box
                className={styles.borderedTitleNumberedDescNumbers}
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginRight="medium"
                background="criticalLight"
                color="brandAccent"
              >
                <StyledHeading level="2" weight="strong">
                  {Number(index) + 1}
                </StyledHeading>
              </Box>

              <li>{children}</li>
            </Box>
          </Box>
        ),
        ol: ({ children }) => (
          <ol className={styles.borderedTitleNumberedDescList}>
            <Stack space="xlarge">{children}</Stack>
          </ol>
        ),
        a: ({ children, href }) => (
          <SaraButton component={HashLink} to={`${href || '#!'}`}>
            {children}
          </SaraButton>
        ),
      }}
    />
  </Stack>
);

export { BorderedTitleNumberedDesc };
