import React from 'react';
import type { DialogFragment, LinkFragment } from '@seek/cmsu-cms-connect';
import { Cta } from '../CTA/CTA';
import { Box, useResponsiveValue } from 'braid-design-system';
import { StyledHeading } from '../StyledHeading/StyledHeading';

type Props = Pick<DialogFragment, 'dialogHeading' | 'dialogAction'>;

const DialogAction = ({ dialogHeading, dialogAction }: Props) => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    desktop: false,
    tablet: false,
    wide: false,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: false,
    tablet: true,
    wide: false,
  });
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const toBeInColumn = isMobile || dialogAction?.alignment === 'Centre';
  const deskTopMarginLeft = isDeskTop ? 'xxlarge' : 'none';
  const paddingDeskTop = isDeskTop ? 'xlarge' : 'large';
  const paddingTablet = isTablet ? 'large' : 'xxlarge';

  return (
    <Box
      display="flex"
      flexDirection={toBeInColumn ? 'column' : 'row'}
      background="brand"
      padding={toBeInColumn ? paddingDeskTop : paddingTablet}
      height="full"
      borderRadius="xlarge"
      alignItems={toBeInColumn ? undefined : 'center'}
      style={{
        justifyContent: toBeInColumn ? 'space-between' : 'space-around',
      }}
    >
      <StyledHeading
        level={isDeskTop && !toBeInColumn ? '1' : '2'}
        weight="strong"
        align="center"
      >
        {dialogHeading}
      </StyledHeading>
      <Box
        style={{ flexBasis: !toBeInColumn ? '45%' : undefined }}
        marginTop={toBeInColumn ? 'large' : 'none'}
        marginLeft={toBeInColumn ? deskTopMarginLeft : 'xxlarge'}
        marginRight={toBeInColumn && isDeskTop ? 'xxlarge' : 'none'}
      >
        <Cta
          text={dialogAction?.text as string}
          ctaLink={dialogAction?.ctaLink as LinkFragment}
          variant={dialogAction?.variant}
        />
      </Box>
    </Box>
  );
};

export { DialogAction };
