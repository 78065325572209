import type React from 'react';
import type { TextLinkFragment } from '@seek/cmsu-cms-connect';
import { adapter } from '@seek/tealium-adapter';
import {
  Box,
  ContentBlock,
  Dropdown,
  Hidden,
  Inline,
  Stack,
  Link,
  Text,
} from 'braid-design-system';

import { useCMSUContext } from '../../hooks/useCMSUContext';
import { toUrl } from '../../utils';

import * as styles from './SectionMenu.css';
import { vars } from 'braid-design-system/css';

export const tealium = adapter();

export const SectionMenu = ({
  currentPage,
  links,
}: {
  currentPage: string;
  links: TextLinkFragment[];
}) => {
  const { language } = useCMSUContext();

  const uniqueTrackingId = 'dropdown-link';

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    if (window !== undefined) {
      const index = e.currentTarget.selectedIndex;
      const label = e.currentTarget[index].label;
      const url = e.currentTarget[index].dataset.url || '#!';

      const trackingAttributes = {
        elementText: label,
        elementLink: url,
        elementDesign: 'dropdown',
      };

      tealium.link(uniqueTrackingId, trackingAttributes);
      window.location.href = url;
    }
  };

  if (!links) {
    return null;
  }

  const getDropdownValue = () => {
    const linksArray = links.map((item) => item.text);
    return linksArray.includes(currentPage) ? currentPage : '';
  };

  return (
    <Box component="nav" overflow="hidden">
      <Hidden below="tablet">
        <ContentBlock width="large">
          <Box
            component="nav"
            role="navigation"
            display="flex"
            alignItems="center"
            overflow="hidden"
          >
            {links.map((item) => {
              const { id, link, text } = item;
              const active = text === currentPage;
              const url = toUrl(link?.to, language);
              return (
                <Stack key={id} space="none" align="center">
                  <Link href={url}>
                    <Box
                      paddingX="gutter"
                      paddingY="medium"
                      className={styles.tab}
                      style={{
                        boxShadow: active
                          ? `inset 0 -3px 0 0 ${vars.foregroundColor.link}`
                          : undefined,
                      }}
                    >
                      <Text
                        aria-label={text}
                        tone={active ? 'link' : 'neutral'}
                      >
                        <span style={{ fontWeight: active ? '500' : '400' }}>
                          {text}
                        </span>
                      </Text>
                    </Box>
                  </Link>
                </Stack>
              );
            })}
          </Box>
        </ContentBlock>
      </Hidden>
      <Hidden above="mobile">
        <Box paddingY="small" width="full">
          <Inline space="none" align="center">
            <Dropdown
              id="sub-nav"
              arial-label="sub navigation menu"
              label=""
              onChange={handleChange}
              value={getDropdownValue()}
            >
              {links.map((item) => {
                const { id, link, text } = item;
                const url = toUrl(link?.to, language);
                return (
                  <option key={id} value={text} label={text} data-url={url}>
                    {text}
                  </option>
                );
              })}
            </Dropdown>
          </Inline>
        </Box>
      </Hidden>
    </Box>
  );
};
