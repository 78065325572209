import type { LinkFragment } from '@seek/cmsu-cms-connect';

export const toUrl = (
  to: LinkFragment['to'],
  language: string = 'en',
  utmParameters = '',
): string => {
  const isNotEn = Boolean(language) && language !== 'en';
  switch (to?.__typename) {
    case 'ExternalUrl':
      // if the external url is a hash link, we don't want to append the utm parameters
      return utmParameters && to.externalUrl?.slice(0, 1) !== '#'
        ? to.externalUrl?.concat(`?${utmParameters}`) || '#'
        : to.externalUrl || '#';
    case 'Asset':
      return to.url;
    case 'Article':
      const articleSection = to.category?.relatedCategorySection?.sectionName;
      // assuming hirer articles always have a section so the article without section is from candidate site
      const articlePath = articleSection
        ? `/${articleSection}/article/${to.slug}`
        : `/career-advice/article/${to.slug}`;
      return isNotEn ? `/${language}/${articlePath}` : articlePath;
    case 'Page':
      const pageSection = to.relatedPageSection?.sectionName;
      let pagePath = null;
      if (pageSection) {
        pagePath =
          pageSection === to.slug
            ? `/${pageSection}`
            : `/${pageSection}/${to.slug}`;
      } else {
        pagePath = `/page/${to.slug}`;
      }
      return isNotEn ? `/${language}${pagePath}` : pagePath;
    case 'Category':
      const categorySection = to.relatedCategorySection?.sectionName;
      const categoryPath = `/${categorySection?.toLocaleLowerCase()}/category/${
        to.slug
      }`;
      return isNotEn ? `/${language}/${categoryPath}` : categoryPath;
    default:
      return '#!';
  }
};
