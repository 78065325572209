type Columns = 1 | 2 | 3 | 4 | 5 | 6;

/**
 * Converts a number into a Braid column value.
 * This is needed since trying to pass a number to Braid columns will throw type errors.
 */
export const toColumns = (num?: number, fallback: Columns = 1): Columns => {
  // Values need be a const instead of a 'number'
  const values = [1, 2, 3, 4, 5, 6] as const;
  return values.find((value) => value === num) || fallback;
};
