import {
  Box,
  Text,
  TextLink,
  Columns,
  Column,
  ContentBlock,
} from 'braid-design-system';
import React from 'react';

import { Logo } from './Logo';
import { ROUTES } from '../../../routes';
import { linkColor } from '../helpers/constants';
import { useLocalized } from '../helpers/hooks/useLocalized';

const Footer = () => {
  const localeUrl = useLocalized({
    'en-AU': 'seek.com.au',
    'en-NZ': 'seek.co.nz',
  });
  return (
    <Box background="neutralLight" padding="gutter">
      <ContentBlock width="large">
        <Box paddingX="gutter">
          <Columns
            space="medium"
            align="center"
            alignY="center"
            collapseBelow="tablet"
          >
            <Column width="content">
              <Logo />
            </Column>
            <Column>
              <Box />
            </Column>
            <Column width="content">
              <Text>
                <TextLink href={`/${ROUTES.BASE_NAME}/contact-us`}>
                  <span style={{ color: linkColor }}>Contact us</span>
                </TextLink>
              </Text>
            </Column>
            <Column width="content">
              <Text>
                <TextLink href={`/${ROUTES.BASE_NAME}/faqs`}>
                  <span style={{ color: linkColor }}>FAQs</span>
                </TextLink>
              </Text>
            </Column>
            <Column width="content">
              <Text>
                <TextLink href={`/${ROUTES.BASE_NAME}/terms`}>
                  <span style={{ color: '#1b1b1b' }}>Terms</span>
                </TextLink>
              </Text>
            </Column>
            <Column width="content">
              <Text>
                <TextLink href={`https://talent.${localeUrl}`}>
                  <span style={{ color: linkColor }}>Visit {localeUrl}</span>
                </TextLink>
              </Text>
            </Column>
          </Columns>
        </Box>
      </ContentBlock>
    </Box>
  );
};

export { Footer };
