import type {
  ContainersFragment,
  DialogGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Tiles } from '@seek/cmsu-components';
import React from 'react';
type DialogGroupType = 'standard' | 'carousel';

import { Dialog } from '../Dialog/Dialog';
import { CarouselDialogGroup } from './CarouselDialogGroup';

type DialogGroupProps = Pick<DialogGroupFragment, 'dialogItems'> &
  Pick<ContainersFragment, 'tile'> & { dialogGroupType?: DialogGroupType };

const DialogGroup = ({
  dialogItems,
  tile,
  dialogGroupType,
  ...rest
}: DialogGroupProps) => {
  if (dialogGroupType === 'standard') {
    return (
      <Tiles {...tile}>
        {dialogItems?.map((item, key) => (
          <Dialog key={key} {...item} {...rest} />
        ))}
      </Tiles>
    );
  }
  return <CarouselDialogGroup dialogItems={dialogItems} />;
};
export { DialogGroup, type DialogGroupProps };
