import {
  Box,
  ButtonIcon,
  Heading,
  IconChevron,
  Inline,
  Stack,
} from 'braid-design-system';
import { useState, useCallback, type TouchEvent } from 'react';
import { useTransition, animated } from 'react-spring';
type Control = 'ArrowKeys' | 'Dots';
interface Props {
  items: React.ReactNode[];
}
const Carousel = ({ items }: Props) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState('initial');
  const [touchPosition, setTouchPosition] = useState<number | undefined>();

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const currentTouch = e.touches[0].clientX;
    const diff = (touchPosition as number) - currentTouch;

    if (diff > 5 && index < items.length - 1) {
      navigate(index + 1);
    }

    if (diff < -5 && index > 0) {
      navigate(index - 1);
    }
  };

  const navigate = useCallback(
    (key: number) => {
      if (key < index) {
        setDirection('reverse');
        setIndex(key);
        return;
      }
      setDirection('initial');
      setIndex(key);
    },
    [index],
  );

  const transitions = useTransition(index, (p) => p, {
    initial: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    from: {
      transform:
        direction === 'reverse'
          ? 'translate3d(-100%,0,0)'
          : 'translate3d(100%,0,0)',
    },
    enter: {
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      transform:
        direction === 'reverse'
          ? 'translate3d(100%,0,0)'
          : 'translate3d(-100%,0,0)',
      position: 'absolute',
      left: '0',
      right: '0',
    },
  });

  return (
    <Stack space="medium">
      <Box overflow="hidden" padding="medium">
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            <Box onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
              {items[item]}
            </Box>
          </animated.div>
        ))}
      </Box>
      <Box>
        <NavigateButtonSection
          items={items}
          index={index}
          navigate={navigate}
        />
      </Box>
    </Stack>
  );
};

const NavigateButtonSection = ({
  items,
  navigate,
  index = 0,
}: {
  items: React.ReactNode[];
  control?: Control;
  navigate: (key: number) => void;
  index?: number;
}) => (
  <Inline space="small" align="center" alignY="center">
    {index > 0 && (
      <ButtonIcon
        id="carousel-prev-button"
        label="Previous"
        size="large"
        onClick={() => navigate(index - 1)}
        icon={<IconChevron direction="left" tone="brandAccent" />}
      />
    )}
    <Heading level="4">
      {index + 1} of {items.length}
    </Heading>
    {index < items.length - 1 && (
      <ButtonIcon
        id="carousel-next-button"
        label="Next"
        size="large"
        onClick={() => navigate(index + 1)}
        icon={<IconChevron direction="right" tone="brandAccent" />}
      />
    )}
  </Inline>
);

export { Carousel };
