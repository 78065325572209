import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import { Box, Heading, Stack } from 'braid-design-system';
import React from 'react';

import type { ArrayElement } from '../helpers/types';

import * as styles from './Heading.css';

type Props = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
>;

const BorderedDescription = ({ title, richTextDescription }: Props) => (
  <Stack space="medium">
    {Boolean(title) && <Heading level="2">{title}</Heading>}
    <Box className={styles.borderedDescriptionBorderLeft} paddingLeft="gutter">
      <RichText content={richTextDescription?.raw} />
    </Box>
  </Stack>
);

export { BorderedDescription };
