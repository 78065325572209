import React from 'react';
import type {
  ButtonLinkFragment,
  CardPrimaryFragment,
  CButtonVariant,
  LinkFragment,
} from '@seek/cmsu-cms-connect';
import { Action } from '../Action/Action';
import { Box, useResponsiveValue } from 'braid-design-system';
import { StyledHeading } from '../StyledHeading/StyledHeading';

type Props = Pick<CardPrimaryFragment, 'heading' | 'actionGroup'> & {
  singleCard?: boolean;
};

const CardAction = ({ heading, actionGroup, singleCard }: Props) => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    desktop: false,
    tablet: false,
    wide: false,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: false,
    tablet: true,
    wide: false,
  });
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const toBeInColumn = isMobile || singleCard;
  const deskTopMarginLeft = isDeskTop ? 'xxlarge' : 'none';
  const paddingDeskTop = isDeskTop ? 'xlarge' : 'large';
  const paddingTablet = isTablet ? 'large' : 'xxlarge';
  return (
    <Box
      display="flex"
      flexDirection={toBeInColumn ? 'column' : 'row'}
      background="brand"
      padding={toBeInColumn ? paddingDeskTop : paddingTablet}
      height="full"
      borderRadius="xlarge"
      alignItems={toBeInColumn ? undefined : 'center'}
      style={{
        justifyContent: toBeInColumn ? 'space-between' : 'space-around',
      }}
    >
      <StyledHeading
        level={isDeskTop && !toBeInColumn ? '1' : '2'}
        weight="strong"
        align="center"
      >
        {heading?.text}
      </StyledHeading>
      <Box
        style={{ flexBasis: !toBeInColumn ? '45%' : undefined }}
        marginTop={toBeInColumn ? 'large' : 'none'}
        marginLeft={toBeInColumn ? deskTopMarginLeft : 'xxlarge'}
        marginRight={toBeInColumn && isDeskTop ? 'xxlarge' : 'none'}
      >
        <Action
          text={(actionGroup?.actions[0] as ButtonLinkFragment).text as string}
          link={actionGroup?.actions[0].link as LinkFragment}
          variant={'ghost' as CButtonVariant}
        />
      </Box>
    </Box>
  );
};

export { CardAction };
