import type { ActionGroupFragment } from '@seek/cmsu-cms-connect';
import { Box, Inline, Stack } from 'braid-design-system';
import React from 'react';
import { ActionRenderer } from '../../renderer/Action/ActionRenderer';

type Props = Pick<ActionGroupFragment, 'actions' | 'inline' | 'display'> & {
  size?: string | null;
};

const ActionGroup = ({ actions, inline, size, display }: Props) => {
  const filtered = actions.filter(
    (action) =>
      !(
        action.link?.to?.__typename === 'ExternalUrl' &&
        action.link.to.externalUrl === '-'
      ),
  );

  if (filtered.length === 0) {
    return null;
  }

  if (size === 'full') {
    return (
      <Stack space="small">
        {filtered.map(
          (action) =>
            action.__typename &&
            (display != null ? (
              <Box display={display}>
                <ActionRenderer
                  key={action.id}
                  action={action as Required<typeof action>}
                />
              </Box>
            ) : (
              <ActionRenderer
                key={action.id}
                action={action as Required<typeof action>}
              />
            )),
        )}
      </Stack>
    );
  }

  return (
    <Inline
      space="gutter"
      alignY="center"
      collapseBelow="tablet"
      align={inline?.align || 'left'}
    >
      {filtered.map(
        (action) =>
          action.__typename && (
            <ActionRenderer
              key={action.id}
              action={action as Required<typeof action>}
            />
          ),
      )}
    </Inline>
  );
};
export { ActionGroup };
