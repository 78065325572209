import type { DialogGroupFragment } from '@seek/cmsu-cms-connect';
import { Box, Tiles } from 'braid-design-system';
import React from 'react';
import { useScreenSize } from 'src/client/hooks/useScreenSize';
import { Carousel } from '../Carousel/Carousel';
import { DisclosureDialog } from '../Dialog/DisclosureDialog';

type Props = Pick<DialogGroupFragment, 'dialogItems'>;

const CarouselDialogGroup = ({ dialogItems }: Props) => {
  const { isMobile, isTablet } = useScreenSize();
  const carouselItems = [];
  const chunkSize = isMobile || isTablet ? 1 : 2;
  for (let i = 0; i < dialogItems.length; i = i + chunkSize) {
    carouselItems.push(
      <Tiles space={chunkSize === 2 ? 'large' : 'none'} columns={chunkSize}>
        {dialogItems.slice(i, i + chunkSize).map((item, index: number) => (
          <Box
            key={index}
            padding={(chunkSize === 2 && 'large') || 'none'}
            marginX={(chunkSize === 2 && 'medium') || 'none'}
            height="full"
            style={
              chunkSize === 2
                ? { boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.147727)' }
                : {}
            }
          >
            <DisclosureDialog {...item} />
          </Box>
        ))}
      </Tiles>,
    );
  }
  return <Carousel items={carouselItems} />;
};

export { CarouselDialogGroup };
