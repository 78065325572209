import React from 'react';
import type { BorderedTitleDescHeadingProps } from '../../modules/Heading/BorderedTitleDescHeading';
import type { ActionProps } from '../../modules/Action/Action';
import type { SaraBlockProps } from '../../modules/SaraBlock/SaraBlock';
import type { CardProps } from '../../modules/Card/Card';
import type { HeadingProps } from '../../modules/Heading/Heading';
import type { ViewPoints } from 'src/client/hooks/useScreenSize';
import { ContactForm } from '../../modules';
import type { DialogGroupProps } from '../../modules/DialogGroup/DialogGroup';
import { Box, List, Text } from 'braid-design-system';

const getPageProps: (
  viewPoint: ViewPoints,
  slug: string,
) => {
  [x: number]:
    | Pick<
        SaraBlockProps,
        | 'dummySpace'
        | 'fullWidth'
        | 'paddingBottom'
        | 'paddingTop'
        | 'background'
        | 'saraMarginTop'
        | 'saraBlockWidth'
        | 'noBottomBorder'
      >
    | Pick<HeadingProps, 'headingType'>
    | Pick<CardProps, 'cardGroupType'>
    | Pick<ActionProps, 'hashlink'>
    | Pick<BorderedTitleDescHeadingProps, 'headingLevel' | 'richTextRenderers'>
    | Pick<DialogGroupProps, 'dialogGroupType'>
    | undefined;
} = ({ isDesktop, isWide }, slug) => {
  if (['sara-home', 'sara-home-nz'].includes(slug)) {
    return {
      0: {
        headingType: 'borderedTitleDesc',
      },
      2: {
        headingType: 'borderedTitle',
        dummySpace: isDesktop || isWide ? 'xxlarge' : 'none',
      },
    };
  } else if (['sara-enter', 'sara-enter-nz'].includes(slug)) {
    return {
      0: { headingType: 'borderedTitleNumberedDesc' },
    };
  } else if (['sara-categories', 'sara-categories-nz'].includes(slug)) {
    return {
      0: {
        dialogGroupType: 'standard',
      },
      1: {
        dialogGroupType: 'carousel',
        paddingTop: 'small',
      },
    };
  } else if (['sara-why-enter', 'sara-why-enter-nz'].includes(slug)) {
    return {
      0: {
        cardGroupType: 'coloredBackground',
        fullWidth: true,
        paddingBottom: isDesktop || isWide ? 'xxxlarge' : undefined,
      },
      1: {
        headingType: 'borderedTitle',
        dummySpace: isDesktop || isWide ? 'xxlarge' : 'none',
        saraMarginTop: isDesktop || isWide ? '-150px' : undefined,
      },
    };
  } else if (['sara-entry-tips', 'sara-entry-tips-nz'].includes(slug)) {
    return {
      0: {
        cardGroupType: 'article',
      },
      1: {
        cardGroupType: 'article',
      },
    };
  } else if (
    [
      'sara-winners-finalists',
      'sara-winners-finalists-nz',
      'sara-winners-finalists-2021',
      'sara-winners-finalists-nz-2021',
    ].includes(slug)
  ) {
    return {
      0: {
        headingType: 'borderedTitleDesc',
        headingLevel: '4',
        islongBottomBorder: true,
      },
      1: {
        background: 'customLight',
      },
    };
  } else if (['sara-event', 'sara-event-nz'].includes(slug)) {
    return {
      0: {
        dummySpace: isDesktop || isWide ? 'xxlarge' : 'none',
        richTextRenderers: {
          p: ({ children }) => (
            <Box paddingY="medium">
              <Text
                tone={
                  (children as any)?.[0]?.props?.node?.text === 'Coming soon'
                    ? 'brandAccent'
                    : undefined
                }
              >
                {children}
              </Text>
            </Box>
          ),
        },
      },
    };
  } else if (['sara-contact-us'].includes(slug)) {
    return {
      0: {
        saraImageContentChildren: <ContactForm />,
        saraBlockWidth: 'medium',
      },
    };
  } else if (['sara-terms', 'sara-terms-nz'].includes(slug)) {
    return {
      0: {
        headingType: 'article',
        noBottomBorder: true,
        richTextRenderers: {
          a: ({ children, href }) => <a href={`${href || '#!'}`}>{children}</a>,
          ol: ({ children }) => (
            <Box paddingLeft="gutter">
              <List type="alpha" space="large">
                {children}
              </List>
            </Box>
          ),
          ul: ({ children }) => (
            <Box paddingLeft="large">
              <List space="large">{children}</List>
            </Box>
          ),
          underline: (
            {
              children,
            } /* Richtext does not support nested list. For nested bulletpoint with circle, content are underlined in hygraph*/,
          ) => (
            <Box paddingY="none" paddingLeft="xlarge">
              <ul style={{ listStyleType: 'circle' }}>
                <li>{children}</li>
              </ul>
            </Box>
          ),
        },
      },
    };
  }

  return {};
};

export { getPageProps };
