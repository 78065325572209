import type {
  BannerPrimaryFragment,
  ButtonLinkFragment,
} from '@seek/cmsu-cms-connect';
import { renderers, RichText } from '@seek/cmsu-rich-text';
import { Box, ButtonLink, ContentBlock, Stack } from 'braid-design-system';
import React, { useMemo, type FunctionComponent } from 'react';
import { Heading } from '@seek/cmsu-components';
import { StyledHeading } from '../StyledHeading/StyledHeading';

import * as styles from './Banner.css';
import { SaraButton } from '../SaraButton/SaraButton';
import type { BraidButtonLinkProps } from '../helpers/types';
import type { ImageProps } from '@graphcms/rich-text-types';
import { toUrl } from '@seek/cmsu-components/src/utils/toUrl';

import SaraTypemark from './images/seek-sara-typemark.png';

type Props = BannerPrimaryFragment;

const Banner: FunctionComponent<Props> = ({
  BannerPrimary_image,
  actionGroup,
  heading,
  paragraph,
  footnotes,
}) => {
  const bannerButton =
    actionGroup && (actionGroup.actions[0] as ButtonLinkFragment);

  const modifiedBannerUrl = useMemo(() => {
    const url = new URL(BannerPrimary_image.url);
    const pathSegments = url.pathname.split('/');
    const urlImageId = pathSegments[pathSegments.length - 1];
    return `${url.origin}/${urlImageId}`;
  }, [BannerPrimary_image]);

  return (
    <>
      <Box
        position="relative"
        width="full"
        className={styles.container}
        style={{
          background: `url(${modifiedBannerUrl})`,
          backgroundSize: 'cover',
        }}
        padding="gutter"
      >
        <Box boxShadow="borderBrandAccent">
          <ContentBlock width="large">
            <Box padding="xlarge">
              <Box
                className={styles.saraImg}
                style={{
                  backgroundImage: `url(${SaraTypemark})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              />
            </Box>
            <Box className={styles.contentContainer} paddingBottom="large">
              <Stack space="large" align="center">
                <Box textAlign="center" paddingTop="small">
                  {heading && (
                    <StyledHeading level="2" weight="strong">
                      <Heading {...heading} text={heading?.text || ''} />
                    </StyledHeading>
                  )}
                </Box>
                <Box
                  textAlign={'center'}
                  paddingX={{ mobile: 'large' }}
                  zIndex={1}
                >
                  {paragraph && (
                    <RichText content={paragraph.Paragraph_text?.raw} />
                  )}
                </Box>
                {bannerButton && (
                  <Box textAlign="center" zIndex={1}>
                    <SaraButton
                      component={ButtonLink}
                      tone={
                        (bannerButton.tone as BraidButtonLinkProps['tone']) ||
                        'brandAccent'
                      }
                      href={toUrl(bannerButton?.link?.to)}
                      size="standard"
                      variant={
                        (bannerButton.variant as BraidButtonLinkProps['variant']) ||
                        'solid'
                      }
                      target="_blank"
                    >
                      <Box paddingX="large">{bannerButton.text}</Box>
                    </SaraButton>
                  </Box>
                )}
              </Stack>
            </Box>
          </ContentBlock>
          {footnotes && (
            <RichText
              content={footnotes.Paragraph_text?.raw}
              renderers={{
                ...renderers,
                img: ({ src }: Partial<ImageProps> & { alt?: string }) => (
                  <Box
                    marginX={'none'}
                    marginY={'none'}
                    style={{
                      backgroundImage: `url(${src})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50%',
                      width: '100%',
                      height: '50px',
                    }}
                  />
                ),
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export { Banner };
