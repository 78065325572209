import type { Icon } from '@seek/cmsu-cms-connect';
import {
  IconAdd,
  IconArrow,
  IconBookmark,
  IconCaution,
  IconChevron,
  IconClear,
  IconCompany,
  IconCompose,
  IconCopy,
  IconCreditCard,
  IconCritical,
  IconDate,
  IconDelete,
  IconDesktop,
  IconDocument,
  IconDocumentBroken,
  IconDownload,
  IconEdit,
  IconEducation,
  IconFilter,
  IconFlag,
  IconGrid,
  IconHeart,
  IconHelp,
  IconHistory,
  IconHome,
  IconImage,
  IconInfo,
  IconInvoice,
  IconLanguage,
  IconLink,
  IconLinkBroken,
  IconList,
  IconLocation,
  IconMail,
  IconMinus,
  IconMobile,
  IconMoney,
  IconNewWindow,
  IconNote,
  IconNotification,
  IconOverflow,
  IconPeople,
  IconPersonAdd,
  IconPersonVerified,
  IconPhone,
  IconPlatformAndroid,
  IconPlatformApple,
  IconPositive,
  IconPrint,
  IconProfile,
  IconPromote,
  IconRecommended,
  IconRefresh,
  IconResume,
  IconSearch,
  IconSecurity,
  IconSend,
  IconSent,
  IconSettings,
  IconShare,
  IconSocialFacebook,
  IconSocialGitHub,
  IconSocialInstagram,
  IconSocialLinkedIn,
  IconSocialMedium,
  IconSocialTwitter,
  IconSocialYouTube,
  IconStar,
  IconStatistics,
  IconSubCategory,
  IconTag,
  IconThumb,
  IconTick,
  IconTime,
  IconTip,
  IconUpload,
  IconVideo,
  IconVisibility,
  IconWorkExperience,
  IconZoomIn,
  IconZoomOut,
} from 'braid-design-system';
import React from 'react';

const icons = {
  Add: IconAdd,
  Arrow: IconArrow,
  Bookmark: IconBookmark,
  Caution: IconCaution,
  Chevron: IconChevron,
  Clear: IconClear,
  Company: IconCompany,
  Compose: IconCompose,
  Copy: IconCopy,
  CreditCard: IconCreditCard,
  Critical: IconCritical,
  Date: IconDate,
  Delete: IconDelete,
  Desktop: IconDesktop,
  Document: IconDocument,
  DocumentBroken: IconDocumentBroken,
  Download: IconDownload,
  Edit: IconEdit,
  Education: IconEducation,
  Filter: IconFilter,
  Flag: IconFlag,
  Grid: IconGrid,
  Heart: IconHeart,
  Help: IconHelp,
  History: IconHistory,
  Home: IconHome,
  Image: IconImage,
  Info: IconInfo,
  Invoice: IconInvoice,
  Language: IconLanguage,
  Link: IconLink,
  LinkBroken: IconLinkBroken,
  List: IconList,
  Location: IconLocation,
  Mail: IconMail,
  Minus: IconMinus,
  Mobile: IconMobile,
  Money: IconMoney,
  NewWindow: IconNewWindow,
  Note: IconNote,
  Notification: IconNotification,
  Overflow: IconOverflow,
  People: IconPeople,
  PersonAdd: IconPersonAdd,
  PersonVerified: IconPersonVerified,
  Phone: IconPhone,
  PlatformAndroid: IconPlatformAndroid,
  PlatformApple: IconPlatformApple,
  Positive: IconPositive,
  Print: IconPrint,
  Profile: IconProfile,
  Promote: IconPromote,
  Recommended: IconRecommended,
  Refresh: IconRefresh,
  Resume: IconResume,
  Search: IconSearch,
  Security: IconSecurity,
  Send: IconSend,
  Sent: IconSent,
  Settings: IconSettings,
  Share: IconShare,
  SocialFacebook: IconSocialFacebook,
  SocialGitHub: IconSocialGitHub,
  SocialInstagram: IconSocialInstagram,
  SocialLinkedIn: IconSocialLinkedIn,
  SocialMedium: IconSocialMedium,
  SocialTwitter: IconSocialTwitter,
  SocialYouTube: IconSocialYouTube,
  Star: IconStar,
  Statistics: IconStatistics,
  SubCategory: IconSubCategory,
  Tag: IconTag,
  Thumb: IconThumb,
  Tick: IconTick,
  Time: IconTime,
  Tip: IconTip,
  Upload: IconUpload,
  Video: IconVideo,
  Visibility: IconVisibility,
  WorkExperience: IconWorkExperience,
  ZoomIn: IconZoomIn,
  ZoomOut: IconZoomOut,
};

export const renderIcon = (iconName: Icon) => {
  const IconComponent = icons[iconName];

  if (IconComponent) {
    if (IconComponent === IconArrow) {
      return <IconArrow direction="right" />;
    }

    return <IconComponent />;
  }

  return null;
};
