import type { CImageBlockGroup } from '@seek/cmsu-cms-connect';
import { Tiles } from '@seek/cmsu-components';
import { Box } from 'braid-design-system';
import React from 'react';
import { ImageBlock } from '../ImageBlock/ImageBlock';

type Props = Pick<Partial<CImageBlockGroup>, 'items' | 'tile'>;

const ImageBlockGroup = ({ items, tile }: Props) => (
  <Tiles {...tile}>
    {items?.map((item, key) => (
      <Box paddingY="small" key={key}>
        <ImageBlock heading={item.heading} image={item.image} />
      </Box>
    ))}
  </Tiles>
);

export { ImageBlockGroup };
