import React from 'react';
import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { Box, Text } from 'braid-design-system';
import { Heading } from '@seek/cmsu-components';
import { RichText } from '@seek/cmsu-rich-text';

type Props = Pick<CardPrimaryFragment, 'heading' | 'image' | 'paragraph'>;

const Article = ({ heading, paragraph, image }: Props) => (
  <Box
    style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.147727)' }}
    height="full"
    display="flex"
    flexDirection="column"
    background="surface"
  >
    <Box
      borderRadius="standard"
      style={{
        backgroundImage: `url(${image?.url})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '150px',
      }}
    />
    <Box
      paddingX="large"
      paddingTop={{
        mobile: 'none',
        desktop: 'small',
        wide: 'large',
      }}
      flexGrow={1}
      display="flex"
      flexDirection="column"
    >
      {heading?.text ? <Heading {...heading} /> : null}
      {paragraph && (
        <RichText
          wrapper={({ children }) => (
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="spaceBetween"
            >
              {children}
            </Box>
          )}
          content={paragraph.Paragraph_text?.raw}
          renderers={{
            p: ({ children }) => (
              <Box paddingY="gutter">
                <Text>{children}</Text>
              </Box>
            ),
          }}
        />
      )}
    </Box>
  </Box>
);

export { Article };
