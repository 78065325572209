import { Box, ContentBlock, Stack, Text, List } from 'braid-design-system';
import React from 'react';

import ErrorSvg from '../images/error-icon.svg';
import { Helm as Helmet } from '../modules/Helmet/Helmet';
import { TextLink } from '../modules/TextLink/TextLink';
import { BraidWrapper } from '../modules/BraidWrapper/BraidWrapper';

const page = 'Not Found';

const NotFound = () => (
  <BraidWrapper>
    <Helmet title={page} />
    <Box background="neutralSoft" paddingX="gutter" paddingY="xxlarge">
      <ContentBlock width="small">
        <Box
          background="surface"
          paddingX="large"
          paddingY="xlarge"
          borderRadius="standard"
        >
          <Stack space="large" align="center">
            <Box
              style={{
                width: '100px',
                height: '100px',
                background: `url(data:image/svg+xml;utf8,${encodeURIComponent(
                  ErrorSvg,
                )})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            />
            <Stack space="medium" align="center">
              <Text size="large">Oops!</Text>
              <Text tone="secondary">
                Something’s not right. Please refresh the page and try again.
              </Text>
              <Box paddingTop="large">
                <Stack space="medium">
                  <Text tone="secondary">Alternatively:</Text>
                  <List type="number">
                    <Text tone="secondary">
                      Check the URL, there may be a typo.
                    </Text>
                    <Text tone="secondary">
                      Try going back to the <TextLink to="/">CMS</TextLink> home
                      page.
                    </Text>
                  </List>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </ContentBlock>
    </Box>
  </BraidWrapper>
);

export { NotFound };
