import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62Sz1LCMBDG7zzFXpyRQ5jy34aLb+KEZikLbRLTVIsO7+4kpFoqIAdPbZqv3/529xu9pDs7VesxfA4AHDaOiYJyxSFD5dCuBgBbpHzrOIyT5MGfS9Gwd5Jue/6plc2SxDSrwXEwit6T//Oe9r2nN7zXItvnVtdKMosGheOgdHzt3Vf0gRwyrZwg5e/+7g/gg5GS2HBI0y7SLCBpIzJyBw7J6Ox2Hm47pTNdaMvhTdhHxl7Gr7nZL+WkHgZCbSVaZoWkuuprsmHXdxl8JVWmEAcOmwID466uHG0OzLeGyt0avRFSksqZ06ZfajrsCtbaOV1e0vzgPF3G8U8myWLmSPtl6aIuw8DD9hg5LKsu5MXpuLNS6Wmip1EVuPEtmQYqXZC88GOEyK1+5zDu7vo8WSLYXq3vM2FzUr82F9ieS5QkoMosogKhJDyWpNpkL2eJaYbBv62WhBOAV7WLWbRBO3aEsyiMy+iXnwe0uwN2R8S8KHIvTkHp4szvw+kb/DWhNJ30J9Q2HhXz6yzXFYuo+M6l0goj0RfkTbm1CgUAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = '_9jr3nb6';
export var BannerTextColumnBoxStyle = '_9jr3nb4';
export var ShopfrontBannerTextColumnBoxStyle = '_9jr3nb5';
export var VerticalDivider = '_9jr3nb8';
export var VerticalDividerText = '_9jr3nba';
export var VerticalDividerWrapper = '_9jr3nb7';
export var VerticalLine = '_9jr3nb9';
export var bannerContainer = '_9jr3nb0';
export var headerImageStyle = '_9jr3nb2';
export var headerImageStyleEnlarged = '_9jr3nb1';
export var tertiaryImageStyle = '_9jr3nb3';