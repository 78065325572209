import type {
  ContainersFragment,
  SaraImageContentBlockFragment,
} from '@seek/cmsu-cms-connect';
import {
  type NodeRendererType,
  renderers,
  RichText,
} from '@seek/cmsu-rich-text';
import { Box, Inline, Stack } from 'braid-design-system';
import { HeadingGroup } from '../HeadingGroup/HeadingGroup';
import { Cta } from '../CTA/CTA';

type Props = Pick<SaraImageContentBlockFragment, 'blocks' | 'richDescription'> &
  Pick<ContainersFragment, 'tile'> & {
    saraImageContentChildren?: React.ReactNode;
    richTextRenderers?: NodeRendererType;
  };

const ImageContentBlockWrapper = ({
  blocks,
  richDescription,
  saraImageContentChildren,
  richTextRenderers,
  tile,
  ...rest
}: Props) => (
  <Stack space="medium">
    {richDescription &&
      Boolean(richDescription?.raw?.children[0]?.children[0]?.text) && (
        <Box>
          <RichText
            content={richDescription.raw}
            renderers={{ ...renderers, ...richTextRenderers }}
          />
        </Box>
      )}
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      paddingBottom="medium"
    >
      <Box width="full">{saraImageContentChildren}</Box>
      <Stack space="gutter">
        {blocks?.map((block, key) => {
          switch (block.__typename) {
            case 'CTA':
              return (
                <Inline space="medium">
                  <Cta key={key} {...block} target="_blank" />
                </Inline>
              );
            case 'HeadingGroup':
              return (
                <HeadingGroup
                  tile={tile}
                  headingGroupItems={block.headingGroupItems}
                  key={key}
                  {...{ richTextRenderers }}
                  {...rest}
                />
              );
            default:
              return null;
          }
        })}
      </Stack>
    </Box>
  </Stack>
);

export { ImageContentBlockWrapper };
