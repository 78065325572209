import { createForm } from '@seek/forms-ui';
import React from 'react';

import { Form } from './components/form';
const FORM_NAME = 'contact-us-form';

interface FormValues {
  name: string;
  company: string;
  email: string;
  enquiry: string;
}

const initialValues: FormValues = {
  name: '',
  company: '',
  email: '',
  enquiry: '',
};

const { FormProvider, useField } = createForm<FormValues, any>(FORM_NAME);

const ContactForm = () => (
  <FormProvider initialValues={initialValues}>
    {({ handleSubmit, resetForm, setFieldValue }) => (
      <Form
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        resetForm={resetForm}
        useField={useField}
      />
    )}
  </FormProvider>
);

export { ContactForm };
