type Props = {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
};

const ConditonalWrapper = ({ condition, wrapper, children }: Props) =>
  condition ? wrapper(children) : children;

export { ConditonalWrapper };
