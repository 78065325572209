import React from 'react';
import type { DialogFragment } from '@seek/cmsu-cms-connect';
import { StandardDialog } from './StandardDialog';
import { DialogAction } from './DialogAction';
import { Box } from 'braid-design-system';

type Props = Pick<
  DialogFragment,
  | 'dialogDescription'
  | 'dialogHeading'
  | 'initialDescription'
  | 'dialogAction'
  | 'image'
>;

const Dialog = (props: Props) => {
  if (props.dialogAction) {
    return (
      <Box paddingTop="medium">
        <DialogAction {...props} />
      </Box>
    );
  }
  return <StandardDialog {...props} />;
};

export { Dialog };
