import {
  type BannerShopfrontFragment,
  CParagraphMarginBottom,
} from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import type { EmbedReferences } from '@graphcms/rich-text-types';
import {
  Box,
  Hidden,
  ContentBlock,
  Stack,
  Text,
  Strong,
  TextLink,
} from 'braid-design-system';
import React from 'react';

import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';
import {
  bannerContainer,
  ShopfrontBannerTextColumnBoxStyle,
} from '../styles.css';
import { useTrackLink } from '@seek/cmsu-analytics';

type Props = Pick<
  BannerShopfrontFragment,
  'heading' | 'image' | 'paragraph'
> & {
  footnotes: BannerShopfrontFragment['BannerShopfront_footnotes'];
  form?: JSX.Element;
};

export const HacqShopfrontBanner = ({
  footnotes,
  heading,
  image,
  paragraph,
  form,
}: Props) => (
  <Box
    position="relative"
    display="flex"
    justifyContent="center"
    alignItems="center"
    paddingX="gutter"
    paddingY={{
      mobile: 'gutter',
      tablet: 'xlarge',
    }}
    data-testid="HacqShopfrontBannerTestId"
    className={bannerContainer}
  >
    <Hidden below="tablet">
      <Box
        position="absolute"
        style={{
          ...(image && { backgroundImage: `url(${image.url})` }),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        left={0}
        top={0}
        width="full"
        height="full"
      />
    </Hidden>
    <Box position="relative" zIndex={1} flexGrow={{ mobile: 1 }}>
      <ContentBlock>
        <Box className={ShopfrontBannerTextColumnBoxStyle}>
          <Stack space={'large'}>
            <Hidden above="mobile">
              <Box position="relative" style={{ minHeight: 300 }}>
                <Box
                  position="absolute"
                  style={{
                    ...(image && { backgroundImage: `url(${image.url})` }),
                    backgroundPosition: 'center right',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                  left={0}
                  top={0}
                  width="full"
                  height="full"
                />
              </Box>
            </Hidden>
            <Stack space={{ mobile: 'medium', tablet: 'large' }}>
              <Box textAlign={{ mobile: 'center', tablet: 'left' }}>
                <Stack space={{ mobile: 'medium', tablet: 'large' }}>
                  {heading?.text ? <Heading {...heading} /> : null}
                  {paragraph && (
                    <Paragraph
                      content={paragraph.Paragraph_text?.raw}
                      marginBottom={CParagraphMarginBottom.None}
                      align={paragraph.align}
                      tone={paragraph.tone}
                      size={paragraph.size}
                    />
                  )}
                </Stack>
              </Box>

              <Stack space="none" align={'left'}>
                {form}
              </Stack>
              {footnotes && (
                <Box textAlign={{ mobile: 'center', tablet: 'left' }}>
                  <Stack space="medium">
                    {footnotes.map((footnote, index) => (
                      <RichText
                        key={`rte-${index}`}
                        content={footnote.richTextEditorWrapper?.content?.raw}
                        references={
                          footnote.richTextEditorWrapper?.content
                            ?.references as EmbedReferences
                        }
                        renderers={{
                          embed: {
                            EmbedSingleLineText: ({ value, bold }) =>
                              bold ? (
                                <Strong>{value}</Strong>
                              ) : (
                                <span>{value}</span>
                              ),
                          },
                          link: {
                            EmbedSingleLineText: ({
                              value,
                              children,
                              nodeId,
                            }) => {
                              const onClickLink = useTrackLink(nodeId);
                              return (
                                <TextLink
                                  onClick={() =>
                                    onClickLink ? onClickLink() : undefined
                                  }
                                  href={value}
                                >
                                  {children}
                                </TextLink>
                              );
                            },
                          },
                          p: ({ children }) => <Text>{children}</Text>,
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
              )}
            </Stack>
          </Stack>
        </Box>
      </ContentBlock>
    </Box>
  </Box>
);
