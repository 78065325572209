import { Heading as BraidHeading } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import type { ComponentProps } from 'react';

interface Props {
  level: ComponentProps<typeof BraidHeading>['level'];
  align?: ComponentProps<typeof BraidHeading>['align'];
  tone?: keyof (typeof vars)['backgroundColor'];
  weight?: 'strong' | ComponentProps<typeof BraidHeading>['weight'];
  children: React.ReactNode;
}

export const StyledHeading = ({
  level,
  align,
  tone,
  weight,
  children,
}: Props) => (
  <BraidHeading level={level} align={align}>
    <span
      style={{
        color: tone ? vars.backgroundColor[tone] : 'inherit',
        fontWeight: weight === 'strong' ? 700 : weight,
      }}
    >
      {children}
    </span>
  </BraidHeading>
);
