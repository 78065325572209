import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from '../App';
import { HYGRAPH_CONTENT_API } from '../config';
import { ConfigProvider } from './components/modules/helpers/hooks/useConfig';

import { ROUTES } from './routes';

const client = new ApolloClient({
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  link: createHttpLink({
    uri: HYGRAPH_CONTENT_API,
  }),
  headers: {
    'hyg-stale-if-error': '86400',
    'hyg-stale-while-revalidate': '300',
  },
});

const config = window.__CONFIG || {};

const container = document.getElementById('app') as Element;
hydrateRoot(
  container,
  <BrowserRouter basename={ROUTES.BASE_NAME}>
    <ApolloProvider client={client}>
      <ConfigProvider config={config}>
        <App />
      </ConfigProvider>
    </ApolloProvider>
  </BrowserRouter>,
);
