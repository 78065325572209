import type { CtaFragment } from '@seek/cmsu-cms-connect';
import { Box, Button, ButtonLink } from 'braid-design-system';
import React, { type FunctionComponent } from 'react';
import { SaraButton } from '../SaraButton/SaraButton';
import {
  AlignmentTypeValues,
  type BraidBoxProps,
  type BraidButtonLinkProps,
} from '../helpers/types';

type Props = Partial<Omit<CtaFragment, '__typename'>> & {
  hashlink?: boolean;
  target?: BraidButtonLinkProps['target'];
};

const Cta: FunctionComponent<Props> = ({
  ctaLink,
  text,
  alignment,
  color,
  variant,
  target,
  hashlink = false,
}) => {
  const href = (
    ctaLink as { to: { __typename: 'ExternalUrl'; externalUrl: string } }
  )?.to.externalUrl;
  return (
    <Box
      textAlign={
        alignment
          ? (AlignmentTypeValues[alignment] as BraidBoxProps['textAlign'])
          : undefined
      }
    >
      <SaraButton
        component={!hashlink ? ButtonLink : Button}
        tone={(color as BraidButtonLinkProps['tone']) || 'brandAccent'}
        href={href}
        size="standard"
        variant={(variant as BraidButtonLinkProps['variant']) || 'solid'}
        target={target}
      >
        <Box paddingX="large">{text}</Box>
      </SaraButton>
    </Box>
  );
};

export { Cta };
