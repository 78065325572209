import React from 'react';

import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import { Box, Stack } from 'braid-design-system';

import { StyledHeading } from '../StyledHeading/StyledHeading';
import type { ArrayElement } from '../helpers/types';

import * as styles from './Heading.css';

type Props = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
>;

const BorderedTitleHeading = ({ title, richTextDescription }: Props) => (
  <Stack space="large">
    <Box paddingY="small" paddingLeft={'gutter'} className={styles.borderLeft}>
      <Stack space="xsmall">
        {Boolean(title) && (
          <StyledHeading level="1" weight="strong" tone="brand">
            {title}
          </StyledHeading>
        )}
      </Stack>
    </Box>
    {<RichText content={richTextDescription?.raw} />}
  </Stack>
);

export { BorderedTitleHeading };
