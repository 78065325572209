import 'braid-design-system/reset';
import {
  Box,
  ContentBlock as BraidContent,
  Stack,
  useResponsiveValue,
} from 'braid-design-system';
import { StyledHeading } from '../StyledHeading/StyledHeading';
import type {
  AlignmentType,
  BraidBoxProps,
  BraidStackProps,
  BraidContentBlockProps,
} from '../helpers/types';
import * as styles from './SaraBlock.css';
import type { BoxFragment } from '@seek/cmsu-cms-connect';

export const IMAGE_MARGIN_DESKTOP = '80px';
export const IMAGE_MARGIN_TABLET = '50px';
export const IMAGE_MARGIN_MOBILE = '50px';

type SaraBlockProps = {
  box?: BoxFragment | null;
  heading?: string;
  headingAlignment?: AlignmentType;
  image?: string;
  imageAlignment?: AlignmentType;
  children: React.ReactNode;
  fullWidth?: boolean;
  dummySpace?: BraidStackProps['space'];
  islongBottomBorder?: boolean;
  background?: 'customLight' | 'customDark';
  paddingBottom?: BraidBoxProps['padding'] | 'xxxlarge';
  paddingTop?: BraidBoxProps['padding'];
  saraMarginTop?: React.CSSProperties['marginTop'];
  saraBlockWidth?: BraidContentBlockProps['width'];
  noBottomBorder?: boolean;
};

const SaraBlock = ({
  heading,
  headingAlignment = 'left',
  image,
  imageAlignment,
  children,
  dummySpace,
  fullWidth,
  islongBottomBorder,
  background,
  box,
  paddingBottom,
  paddingTop,
  saraMarginTop,
  saraBlockWidth,
  noBottomBorder,
}: SaraBlockProps) => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    desktop: false,
    tablet: false,
    wide: false,
  });
  const getHeadingBorderStyle = () => {
    if (noBottomBorder) {
      return { width: '0px' };
    }
    if (islongBottomBorder) {
      return { width: '300px', borderBottom: '1px solid #080559' };
    }
    switch (headingAlignment) {
      case 'center':
        return { width: '244px', borderBottom: '1px solid #EC1380' };
      default:
        return { width: '61px', borderBottom: '1px solid #080559' };
    }
  };
  const getFlexDirection: () => BraidBoxProps['flexDirection'] = () => {
    if (isMobile) {
      return 'column';
    }
    if (imageAlignment?.toLowerCase() === 'right') {
      return 'rowReverse';
    }
    return 'row';
  };
  const getPaddingBottom = () => {
    if (paddingBottom === 'xxxlarge') {
      return 'xxlarge';
    }
    return paddingBottom;
  };
  return (
    <>
      <Box
        background={
          fullWidth
            ? (background as 'customLight' | 'customDark') || 'surface'
            : undefined
        }
        textAlign={box?.textAlign || 'left'}
        height="full"
        position="relative"
        style={{ marginTop: saraMarginTop }}
      >
        <BraidContent
          width={
            saraBlockWidth ||
            responsiveValue({
              mobile: 'xsmall',
              desktop: 'large',
              tablet: 'small',
            })!
          }
        >
          <Box
            display={image ? 'flex' : 'block'}
            flexDirection={getFlexDirection()}
            background={
              (background as 'customLight' | 'customDark') || 'surface'
            }
            style={{
              marginTop: image
                ? responsiveValue({
                    mobile: IMAGE_MARGIN_MOBILE,
                    desktop: IMAGE_MARGIN_DESKTOP,
                    tablet: IMAGE_MARGIN_TABLET,
                  })!
                : 'unset',
            }}
          >
            {image && imageAlignment && (
              <Box
                className={[
                  styles.image,
                  imageAlignment?.toLowerCase() === 'right'
                    ? styles.imageRight
                    : styles.imageLeft,
                ]}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            )}
            {image && dummySpace && <Box padding={dummySpace} />}
            <Box
              paddingY="xlarge"
              paddingTop={paddingTop}
              paddingBottom={getPaddingBottom()}
              paddingX={(() => {
                if (!fullWidth) {
                  return 'xlarge';
                }
                if (isMobile) {
                  return 'medium';
                }
                return 'none';
              })()}
              width="full"
            >
              <Stack space="medium">
                {Boolean(heading) && (
                  <Stack space="small" align={headingAlignment}>
                    <StyledHeading level="2" weight="strong">
                      {heading}
                    </StyledHeading>
                    <Box style={getHeadingBorderStyle()} />
                  </Stack>
                )}

                <Box>{children}</Box>
              </Stack>
            </Box>
          </Box>
        </BraidContent>
      </Box>
      {paddingBottom === 'xxxlarge' && (
        <Box padding="xxlarge" width="full" background="surface" />
      )}
    </>
  );
};

export { SaraBlock, type SaraBlockProps };
