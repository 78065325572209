import type { GalleryFragment } from '@seek/cmsu-cms-connect';
import React from 'react';
import { Carousel } from '../Carousel/Carousel';
import { Box } from 'braid-design-system';

type Props = Pick<GalleryFragment, 'assets' | 'tile'>;
import * as styles from './ImageGallery.css';

const ImageGallery = ({ assets }: Props) => (
  <Carousel
    items={assets.map((image, key) => (
      <Box
        key={key}
        style={{
          backgroundImage: `url(${image.url})`,
        }}
        className={styles.imageContainer}
      />
    ))}
  />
);

export { ImageGallery };
