import React from 'react';

import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';
import {
  renderers,
  RichText,
  type NodeRendererType,
} from '@seek/cmsu-rich-text';
import { Box, List, Stack } from 'braid-design-system';
import { StyledHeading } from '../StyledHeading/StyledHeading';
import type { ArrayElement, BraidHeadingProps } from '../helpers/types';

import * as styles from './Heading.css';

type BorderedTitleDescHeadingProps = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
> & {
  headingLevel?: BraidHeadingProps['level'];
  richTextRenderers?: NodeRendererType;
};

const BorderedTitleDescHeading = ({
  title,
  richTextDescription,
  headingLevel,
  richTextRenderers,
}: BorderedTitleDescHeadingProps) => (
  <Stack space="medium">
    <Box
      paddingTop="xxsmall"
      paddingBottom="medium"
      paddingLeft={'gutter'}
      className={styles.borderLeft}
    >
      <Stack space="gutter">
        {Boolean(title) && (
          <StyledHeading
            level={headingLevel ? headingLevel : '1'}
            weight="strong"
            tone="brand"
          >
            {title}
          </StyledHeading>
        )}
        <RichText
          content={richTextDescription?.raw}
          renderers={{
            ...renderers,
            ...richTextRenderers,
            ul: ({ children }) => (
              <Box paddingLeft="medium">
                <List space="small">{children}</List>
              </Box>
            ),
          }}
        />
      </Stack>
    </Box>
  </Stack>
);

export { BorderedTitleDescHeading, type BorderedTitleDescHeadingProps };
