import type { ActionGroupFragment } from '@seek/cmsu-cms-connect';
import { Inline } from 'braid-design-system';
import React from 'react';
import { Action } from '../Action/Action';

type Props = Pick<ActionGroupFragment, 'actions' | 'inline'>;

const ActionGroup = ({ actions, ...rest }: Props) => (
  <Inline space="small" alignY="center" align={rest.inline?.align || 'left'}>
    {actions.map((action, key) => (
      <Action key={key} {...(action as { text: string })} {...rest} />
    ))}
  </Inline>
);

export { ActionGroup };
