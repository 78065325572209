import React from 'react';

import type {
  ContainersFragment,
  SaraHeadingGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Tiles } from '@seek/cmsu-components';
import { Box } from 'braid-design-system';
import { Heading } from '../Heading/Heading';

type Props = Pick<SaraHeadingGroupFragment, 'headingGroupItems'> & {
  tile?: ContainersFragment['tile'];
};

const HeadingGroup = ({ headingGroupItems, tile, ...rest }: Props) => (
  <Tiles {...tile}>
    {headingGroupItems?.map((item, key) => (
      <Box key={key} height="full">
        <Heading
          title={item.title}
          richTextDescription={item.richTextDescription}
          key={key}
          {...rest}
        />
      </Box>
    ))}
  </Tiles>
);

export { HeadingGroup };
