import type { ContainersFragment } from '@seek/cmsu-cms-connect';
import type { Box } from 'braid-design-system';

/**
 * Checks if a container should have a divider between it and the next container.
 */
export const hasDivider = (
  containers: ContainersFragment[],
  currentIndex: number,
): boolean => {
  const thisContainer = containers[currentIndex];
  const nextContainer = containers[currentIndex + 1];

  // Don't add a divider if this or the next container have a background
  if (thisContainer?.box?.background || nextContainer?.box?.background) {
    return false;
  }

  // Don't add a divider if we are on the last container
  if (currentIndex === containers.length - 1) {
    return false;
  }

  return true;
};

const colourMap: Record<string, string> = {
  lightPurple: '#F0D6FA',
  lightCyan: '#E1F8FA',
  lightTurquoise: '#BEEFF3',
};

type BoxBackgroundProps = {
  background?: React.ComponentProps<typeof Box>['background'];
  style: Record<string, any>;
};

export const getBoxBackgroundProps = (background?: string | null) => {
  const backgroundProps: BoxBackgroundProps = {
    background: undefined,
    style: { background: undefined },
  };

  if (background == null) {
    return backgroundProps;
  }

  if (background && colourMap[background]) {
    backgroundProps.style.background = colourMap[background];
  } else if (background === 'customBg') {
    backgroundProps.background = 'brand';
    backgroundProps.style.position = 'relative';
  } else {
    backgroundProps.background =
      (background as React.ComponentProps<typeof Box>['background']) ||
      undefined;
  }

  return backgroundProps;
};
