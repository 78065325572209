import { TextLink as BraidLink } from 'braid-design-system';
import { useHref } from 'react-router';

import { ROUTES } from '../../../routes';

type Props = Omit<React.ComponentProps<typeof BraidLink>, 'href'> & {
  to: string;
  style?: React.CSSProperties;
};

const TextLink = ({ children, to, ...restProps }: Props) => {
  const href = useHref(to);
  const external = !Object.values(ROUTES).includes(to);

  const combinedProps = {
    href: external ? to : href,
    ...restProps,
  };

  return <BraidLink {...combinedProps}>{children}</BraidLink>;
};

export { TextLink };
