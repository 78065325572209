import { Box, Loader } from 'braid-design-system';
import React, { useState } from 'react';

import * as styles from './BrightcoveVideo.css';

interface Props {
  url: string;
}

export const BrightcoveVideo = ({ url }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      className={styles.videoWrapper}
      borderRadius="large"
      display="flex"
      justifyContent="center"
    >
      {!loaded && <Loader aria-label="video loading" size="large" />}
      <iframe
        className={styles.video}
        src={url}
        frameBorder="0"
        onLoad={() => setLoaded(true)}
        allowFullScreen
      />
    </Box>
  );
};
