import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import React from 'react';
import { CardAction } from './CardAction';
import { StandardCard } from './StandardCard';
import { ColoredBackgroundCard } from './ColoredBackgroundCard';
import { ComingSoonCard } from './ComingSoonCard';
import { Article } from './Article';

export type CardGroupType =
  | 'standard'
  | 'coloredBackground'
  | 'comingSoon'
  | 'article';

type CardProps = Pick<
  CardPrimaryFragment,
  'actionGroup' | 'heading' | 'paragraph' | 'image'
> & {
  cardGroupType?: CardGroupType;
  singleCard?: boolean;
};
const Card = ({
  actionGroup,
  cardGroupType,
  heading,
  paragraph,
  image,
  singleCard = false,
}: CardProps) => {
  if (Boolean(actionGroup)) {
    return (
      <CardAction
        heading={heading}
        actionGroup={actionGroup}
        singleCard={singleCard}
      />
    );
  }
  switch (cardGroupType) {
    case 'standard':
      return <StandardCard heading={heading} paragraph={paragraph} />;
    case 'coloredBackground':
      return <ColoredBackgroundCard heading={heading} paragraph={paragraph} />;
    case 'comingSoon':
      return <ComingSoonCard heading={heading} paragraph={paragraph} />;
    case 'article':
      return <Article image={image} heading={heading} paragraph={paragraph} />;

    default:
      return null;
  }
};

export { Card, type CardProps };
