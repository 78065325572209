import React, { Fragment } from 'react';
import { Box, Heading, List, Stack, Strong, Text } from 'braid-design-system';
import type { RichTextProps } from './types';

import {
  IFrame,
  Image,
  Video,
  Class,
  Link,
  Audio,
  Paragraph,
} from './elements';
import { logger } from './utils/logger';
import type { ImageProps } from '@graphcms/rich-text-types';
import { resizeImageMaxSizeFromHygraph } from './utils/imageHelper';
import { BlockQuote } from './components/BlockQuote/BlockQuote';
import { vars } from 'braid-design-system/css';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeaderCell,
} from './components/Table/Table';

function FallbackForCustomAsset({ mimeType }: { mimeType: string }) {
  logger.warn(
    `[@seek/cmsu-rich-text]: Unsupported mimeType encountered: ${mimeType}. You need to write your renderer to render it since we are not opinionated about how this asset should be rendered (check our docs for more info).`,
  );

  return <Fragment />;
}

export const defaultElements: Required<RichTextProps['renderers']> = {
  a: Link,
  class: Class,
  iframe: IFrame,
  video: Video,
  h1: ({ children }) => (
    <Box marginTop={'xlarge'}>
      <Heading level="1">{children}</Heading>
    </Box>
  ),
  h2: ({ children }) => (
    <Box marginTop={'large'}>
      <Heading level="2">{children}</Heading>
    </Box>
  ),
  h3: ({ children }) => (
    <Box marginTop={'medium'}>
      <Heading level="3">{children}</Heading>
    </Box>
  ),
  h4: ({ children }) => (
    <Box marginTop={'gutter'}>
      <Heading level="4">{children}</Heading>
    </Box>
  ),
  h5: ({ children }) => (
    <Box marginTop={'gutter'}>
      <Heading level="4" component="h5">
        {children}
      </Heading>
    </Box>
  ),
  h6: ({ children }) => (
    <Box marginTop={'gutter'}>
      <Heading level="4" component="h6">
        {children}
      </Heading>
    </Box>
  ),
  p: Paragraph,
  bold: ({ children }) => <Strong>{children}</Strong>,
  underline: ({ children }) => <u>{children}</u>,
  italic: ({ children }) => <i>{children}</i>,
  ul: ({ children }) => (
    <List space={'medium'} type="bullet">
      {children}
    </List>
  ),
  ol: ({ children }) => (
    <List space={'medium'} type="number">
      {children}
    </List>
  ),
  li: ({ children }) => <Stack space="medium">{children}</Stack>,

  list_item_child: ({ children }) => <Text component="p">{children}</Text>,
  table: ({ children }) => <Table>{children}</Table>,
  table_body: ({ children }) => <TableBody>{children}</TableBody>,
  table_row: ({ children }) => <TableRow>{children}</TableRow>,
  table_cell: ({ children }) => <TableCell>{children}</TableCell>,
  table_head: ({ children }) => <TableHead>{children}</TableHead>,
  table_header_cell: ({ children }) => (
    <TableHeaderCell>{children}</TableHeaderCell>
  ),
  code: ({ children }) => <code>{children}</code>,
  code_block: ({ children }) => (
    <Box
      background="neutralLight"
      padding="small"
      boxShadow="borderNeutralLight"
    >
      <Text>
        <code>{children}</code>
      </Text>
    </Box>
  ),
  blockquote: ({ children }) => <BlockQuote>{children}</BlockQuote>,
  // RichText renderer uses altText prop for image element, but articles migrated with custom patch (https://myseek.atlassian.net/browse/CU-514) is using alt tag.
  img: ({
    alt,
    altText,
    src,
    title,
    mimeType,
  }: Partial<ImageProps> & { alt?: string }) => (
    <img
      src={
        !mimeType?.includes('svg') && src
          ? resizeImageMaxSizeFromHygraph(src)
          : src
      }
      alt={altText || alt}
      title={title}
      style={{
        maxWidth: '100%',
        paddingTop: vars.space.small,
        paddingBottom: vars.space.small,
      }}
    />
  ),
  Asset: {
    audio: (props) => <Audio {...props} url={props.url} />,
    image: (props) => <Image {...props} src={props.url} />,
    video: (props) => <Video {...props} src={props.url} />,
    font: FallbackForCustomAsset,
    application: FallbackForCustomAsset,
    model: FallbackForCustomAsset,
    text: FallbackForCustomAsset,
  },
  embed: {},
  link: {},
};
