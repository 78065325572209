import {
  CTextAlign,
  type Video as VideoBlockFragment,
} from '@seek/cmsu-cms-connect';
import { BrightcoveVideo } from '@seek/cmsu-components';
import { getBoxBackgroundProps } from '@seek/cmsu-components/src/modules/PageComponent/helper';
import { Box, Text } from 'braid-design-system';
import React from 'react';
type Props = Pick<VideoBlockFragment, 'source' | 'box'>;

const VideoBlock = ({ source, box }: Props) => {
  switch (source?.__typename) {
    case 'BrightcoveVideo':
      return (
        <Box
          textAlign={box?.textAlign || 'left'}
          {...getBoxBackgroundProps(box?.background)}
        >
          <Box
            maxWidth="medium"
            style={{
              marginLeft:
                box?.textAlign === CTextAlign.Left ? 'auto' : undefined,
              marginRight:
                box?.textAlign === CTextAlign.Right ? 'auto' : undefined,
              margin: box?.textAlign === CTextAlign.Center ? 'auto' : undefined,
            }}
          >
            <BrightcoveVideo url={source.videoUrl} />
          </Box>
        </Box>
      );

    default:
      return <Text>YouTube source not supported yet</Text>;
  }
};
export { VideoBlock };
