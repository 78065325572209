import type { StackFragment, TileFragment } from '@seek/cmsu-cms-connect';
import { Tiles as BraidTiles } from 'braid-design-system';
import { toColumns } from '../../utils';

type Props = Partial<
  Pick<TileFragment, 'columns' | 'dividers' | 'responsiveColumnValue'>
> & {
  space?: TileFragment['space'] | StackFragment['space'];
  children: React.ComponentProps<typeof BraidTiles>['children'];
};

export const Tiles = ({
  columns,
  dividers,
  children,
  space,
  responsiveColumnValue,
}: Props) => (
  <BraidTiles
    columns={{
      mobile: toColumns(responsiveColumnValue?.mobile || 1),
      tablet: toColumns(responsiveColumnValue?.tablet || columns),
      desktop: toColumns(responsiveColumnValue?.desktop || columns),
      wide: toColumns(responsiveColumnValue?.wide || columns),
    }}
    dividers={dividers || false}
    space={space || 'gutter'}
  >
    {children}
  </BraidTiles>
);
