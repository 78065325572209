import {
  ButtonLink as BraidButtonLink,
  type Button as BraidButton,
} from 'braid-design-system';
import React from 'react';
import type { HashLink, HashLinkProps } from 'react-router-hash-link';
import type { BraidButtonLinkProps, BraidButtonProps } from '../helpers/types';

type Props = Partial<BraidButtonLinkProps> &
  Partial<HashLinkProps> &
  BraidButtonProps & {
    component?: typeof BraidButtonLink | typeof BraidButton | typeof HashLink;
  };

const SaraButton = ({
  component: Component = BraidButtonLink,
  children,
  href = '',
  ...rest
}: Props) => {
  const combinedProps = {
    href,
    ...rest,
  };
  return <Component {...combinedProps}>{children}</Component>;
};

export { SaraButton };
