import {
  required,
  badWords,
  disallowHtml,
  isEmail,
  maxLength,
} from '@seek/validators-js';
import {
  Actions,
  Alert,
  Box,
  Button,
  Inline,
  Stack,
  Text,
  Textarea,
  TextField,
} from 'braid-design-system';
import React, { type FunctionComponent, useState } from 'react';
import { renderEmail } from 'react-html-email';
import { SaraButton } from '../../SaraButton/SaraButton';
import { EmailContentComponent } from '../EmailContentComponent';

const EMAIL_SOURCE = 'saras@seek.com.au';
const EMAIL_RECIPIENT = ['saras@seek.com.au'];
const EMAIL_SUBJECT = 'SARAs Enquiry';

interface FormProps {
  handleSubmit: any;
  setFieldValue: (
    field: 'name' | 'company' | 'email' | 'enquiry',
    value: string,
  ) => void;
  resetForm: any;
  useField: any;
}

const Form: FunctionComponent<FormProps> = ({
  handleSubmit,
  setFieldValue,
  resetForm,
  useField,
}) => {
  const [invalidResponse, setInvalidResponse] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const name = useField({
    id: 'name',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: { max: 100 },
  });
  const company = useField({
    id: 'company',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: { max: 100 },
  });
  const email = useField({
    id: 'email',
    validators: [required, disallowHtml, isEmail, maxLength] as any,
    validatorProps: { max: 100 },
  });
  const enquiry = useField({
    id: 'enquiry',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: { max: 1000 },
  });

  const endPoint = '/sara/send-email';

  const onSubmit = handleSubmit((formValues: any) => {
    setSubmitting(true);
    fetch(endPoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        from: EMAIL_SOURCE,
        to: EMAIL_RECIPIENT,
        subject: EMAIL_SUBJECT,
        message: renderEmail(
          <EmailContentComponent {...formValues} subject={EMAIL_SUBJECT} />,
        ),
      }),
    })
      .then((response) => {
        setSubmitting(false);
        if (response.ok) {
          resetForm();
          setInvalidResponse(false);
        } else {
          setInvalidResponse(true);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setInvalidResponse(true);
        throw error;
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack space={'medium'}>
        <TextField
          id="name"
          placeholder="Name"
          message={!name.valid && name.errorMessage}
          onClear={() => setFieldValue('name', '')}
          tone={!name.valid ? 'critical' : 'neutral'}
          {...name}
        />
        <TextField
          id="company"
          placeholder="Company"
          message={!company.valid && company.errorMessage}
          onClear={() => setFieldValue('company', '')}
          tone={!company.valid ? 'critical' : 'neutral'}
          {...company}
        />
        <TextField
          id="email"
          placeholder="Email"
          onClear={() => setFieldValue('email', '')}
          message={!email.valid && email.errorMessage}
          tone={!email.valid ? 'critical' : 'neutral'}
          {...email}
        />
        <Textarea
          id="enquiry"
          label=""
          placeholder="Enquiry"
          characterLimit={1000}
          value={enquiry.value}
          onChange={enquiry.onChange}
          onBlur={enquiry.onBlur}
          message={!enquiry.valid && enquiry.errorMessage}
          tone={!enquiry.valid ? 'critical' : 'neutral'}
        />
        {invalidResponse && (
          <Alert tone="critical">
            <Text>Something went wrong, please try again.</Text>
          </Alert>
        )}
        <Actions>
          <Inline space="none">
            <SaraButton
              component={Button}
              tone="brandAccent"
              type="submit"
              loading={submitting}
            >
              <Box paddingX="large">{submitting ? 'Submitting' : 'Submit'}</Box>
            </SaraButton>
          </Inline>
        </Actions>
      </Stack>
    </form>
  );
};

export { Form };
