import {
  type BannerPrimaryFragment,
  CParagraphMarginBottom,
} from '@seek/cmsu-cms-connect';
import type { Country } from '@seek/melways-sites';
import {
  Box,
  Hidden,
  ContentBlock,
  Stack,
  Column,
  useResponsiveValue,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Asset, AssetType } from '../../Asset/Asset';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';
import { getLogoByCountry } from '../../../helpers/getLogoByCounty';
import { BannerTextColumnBoxStyle } from '../styles.css';
import { BannerDivider } from '../BannerDivider';

type Props = Pick<
  BannerPrimaryFragment,
  | 'actionGroup'
  | 'divider'
  | 'footnotes'
  | 'heading'
  | 'logoDisplay'
  | 'paragraph'
  | 'imageHeight'
  | 'isContentRight'
> & {
  country: Country;
  image: BannerPrimaryFragment['BannerPrimary_image'];
};

export const PrimaryBanner = ({
  paragraph,
  heading,
  footnotes,
  actionGroup,
  image,
  divider,
  logoDisplay,
  country,
  imageHeight,
  isContentRight,
}: Props) => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
  });

  let minHeight = '600px';
  if (isMobile) {
    minHeight = '300px';
  } else {
    minHeight = imageHeight && imageHeight === 'Small' ? '300px' : '600px';
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        paddingX="gutter"
        paddingY={{
          mobile: 'xlarge',
        }}
        position="relative"
        data-testid="PrimaryBannerTestId"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight,
          maxWidth: '2000px',
        }}
        width="full"
      >
        <Hidden below="tablet">
          <Box
            position="absolute"
            style={{
              ...(image && { backgroundImage: `url(${image.url})` }),
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            left={0}
            top={0}
            width="full"
            height="full"
          />
        </Hidden>
        <Box position="relative" zIndex={1} flexGrow={{ mobile: 1 }}>
          <ContentBlock>
            <Stack space="gutter">
              {isMobile && Boolean(image?.url) && (
                <Column>
                  <Stack space="none" align="center">
                    <Asset {...image} assetType={AssetType.PRIMARY_BANNER} />
                  </Stack>
                </Column>
              )}
              <Box
                className={BannerTextColumnBoxStyle}
                style={{
                  float: !isMobile && isContentRight ? 'right' : 'none',
                }}
              >
                <Stack space="medium">
                  {logoDisplay && getLogoByCountry(country)}
                  {heading?.text ? <Heading {...heading} /> : null}
                  {paragraph && (
                    <Box paddingY="small">
                      <Paragraph
                        content={paragraph.Paragraph_text?.raw}
                        marginBottom={CParagraphMarginBottom.None}
                        align={paragraph.align}
                        tone={paragraph.tone}
                        size={paragraph.size}
                      />
                    </Box>
                  )}
                  {divider && (
                    <BannerDivider
                      image={divider.image}
                      actions={divider.actionGroup}
                    />
                  )}
                  {actionGroup && <ActionGroup {...actionGroup} />}
                  {footnotes && (
                    <Box
                      color={vars.foregroundColor.secondary}
                      paddingTop="small"
                    >
                      <Paragraph
                        content={footnotes.Paragraph_text?.raw}
                        marginBottom={CParagraphMarginBottom.Small}
                        align={footnotes.align}
                        tone={footnotes.tone}
                        size={footnotes.size}
                      />
                    </Box>
                  )}
                </Stack>
              </Box>
            </Stack>
          </ContentBlock>
        </Box>
      </Box>
    </Box>
  );
};
