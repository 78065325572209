import 'braid-design-system/reset';
import { VocabProvider } from '@vocab/react';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { PageLayout } from './client/components/modules';
import { NotFound, Page } from './client/components/pages';
import { EmailPreview } from './client/components/modules/ContactForm/EmailContentComponent';
import { useEnvironment } from './client/components/modules/helpers/hooks/useContext';

const App = () => {
  const environment = useEnvironment();

  return (
    <VocabProvider language="en">
      <div role="main">
        <Routes>
          <Route element={<PageLayout />}>
            <Route index element={<Page />} />
            <Route path="categories" element={<Page />} />
            <Route path="why-enter" element={<Page />} />
            <Route path="winners-finalists" element={<Page />} />
            <Route path="winners-finalists-2021" element={<Page />} />
            <Route path="faqs" element={<Page />} />
            <Route path="terms" element={<Page />} />
            {/* <Route path="enter" element={<Page />} /> */}
            <Route path="entry-tips" element={<Page />} />
            <Route path="event" element={<Page />} />
            <Route path="contact-us" element={<Page />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          {environment === 'development' && (
            <Route path="email-preview" element={<EmailPreview />} />
          )}
        </Routes>
      </div>
    </VocabProvider>
  );
};

export { App };
