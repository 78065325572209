import React from 'react';
import { ButtonLink as BraidButtonLink } from 'braid-design-system';
import type { ButtonLinkFragment } from '@seek/cmsu-cms-connect';
import { toUrl } from '../../utils/toUrl';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { renderIcon } from '../IconBlockGroup/renderer';

type Props = Omit<ButtonLinkFragment, '__typename'> & {
  uniqueTrackingId?: string;
  trackingAttributes?: Record<string, string>;
} & Pick<ButtonLinkFragment, 'icon' | 'iconPosition'>;

export const ButtonLink = ({
  id,
  text,
  link,
  size,
  tone,
  variant,
  uniqueTrackingId,
  trackingAttributes,
  icon,
  iconPosition,
}: Props) => {
  const onClickLink = useTrackLink(uniqueTrackingId || id, {
    ...trackingAttributes,
    elementDesign: 'button',
  });
  const { language, utmParameters } = useCMSUContext();

  return (
    <BraidButtonLink
      href={toUrl(link?.to, language, utmParameters)}
      tone={tone || undefined}
      variant={variant || 'solid'}
      size={size || 'standard'}
      onClick={() => (onClickLink ? onClickLink() : undefined)}
      role="link"
      icon={(icon && renderIcon(icon)) || undefined}
      iconPosition={iconPosition || undefined}
    >
      {text}
    </BraidButtonLink>
  );
};
