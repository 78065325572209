import type { CardGroupFragment } from '@seek/cmsu-cms-connect';
import { Tiles } from '@seek/cmsu-components';
import React from 'react';
import { Card } from '../Card/Card';

type Props = Pick<CardGroupFragment, 'cards' | 'tile'>;

const CardGroup = ({ cards, tile, ...rest }: Props) => (
  <Tiles {...tile}>
    {cards?.map((card, key) => (
      <Card {...card} key={key} {...rest} singleCard={Boolean(tile)} />
    ))}
  </Tiles>
);

export { CardGroup };
