import React from 'react';
import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import { Box, Stack } from 'braid-design-system';

import { StyledHeading } from '../StyledHeading/StyledHeading';

type Props = Pick<CardPrimaryFragment, 'heading' | 'paragraph'>;

const ColoredBackgroundCard = ({ heading, paragraph }: Props) => (
  <Box background="neutralLight" padding="xlarge" height="full">
    <Stack space="large">
      <Stack space="small">
        {Boolean(heading) && (
          <StyledHeading level="2" weight="strong">
            {heading?.text}
          </StyledHeading>
        )}
        <Box
          style={{
            borderBottom: '1px solid #EC1380',
            width: '222px',
          }}
        />
      </Stack>
      {Boolean(paragraph) && (
        <Box paddingBottom="small">
          <RichText content={paragraph?.Paragraph_text?.raw} />
        </Box>
      )}
    </Stack>
  </Box>
);

export { ColoredBackgroundCard };
