import { Box, Loader } from 'braid-design-system';
import React, { type FunctionComponent } from 'react';
import { BraidWrapper } from '../BraidWrapper/BraidWrapper';

const Loading: FunctionComponent = () => (
  <BraidWrapper>
    <Box
      style={{ height: '100vh', backgroundColor: '#EB008B0D' }}
      padding="xxlarge"
    >
      <Box display="flex" justifyContent="center">
        <Loader />
      </Box>
    </Box>
  </BraidWrapper>
);

export { Loading };
