export const ROUTES = {
  BASE_NAME: 'sara',
  ASSETS: 'assets',
  HOME: '/',
  CATEGORIES: '/categories',
  CONTACTUS: '/contact-us',
  // ENTER: '/enter',
  ENTRY_TIPS: '/entry-tips',
  EVENT: '/event',
  FAQS: '/faqs',
  TERMS: '/terms',
  WINNERS_FINALISTS: '/winners-finalists',
  WINNERS_FINALISTS_2021: '/winners-finalists-2021',
  WHY_ENTER: '/why-enter',
};

export const ROUTES_PAGES_MAPPING = {
  [ROUTES.HOME]: 'Home',
  [ROUTES.CATEGORIES]: 'Categories',
  [ROUTES.CONTACTUS]: 'Contact us',
  // [ROUTES.ENTER]: 'Enter',
  [ROUTES.ENTRY_TIPS]: 'Entry Tips',
  [ROUTES.EVENT]: 'Event',
  [ROUTES.FAQS]: 'FAQs',
  [ROUTES.WINNERS_FINALISTS]: 'Winners & Finalists',
  [ROUTES.WINNERS_FINALISTS_2021]: 'Winners & Finalists 2021',
  [ROUTES.WHY_ENTER]: 'Why Enter',
  [ROUTES.TERMS]: 'Terms',
};
export const ROUTES_MAPPING = {
  [ROUTES.HOME]: 'sara-home',
  [ROUTES.CATEGORIES]: 'sara-categories',
  [ROUTES.CONTACTUS]: 'sara-contact-us',
  // [ROUTES.ENTER]: 'sara-enter',
  [ROUTES.ENTRY_TIPS]: 'sara-entry-tips',
  [ROUTES.EVENT]: 'sara-event',
  [ROUTES.FAQS]: 'sara-faq',
  [ROUTES.WINNERS_FINALISTS]: 'sara-winners-finalists',
  [ROUTES.WINNERS_FINALISTS_2021]: 'sara-winners-finalists-2021',
  [ROUTES.WHY_ENTER]: 'sara-why-enter',
  [ROUTES.TERMS]: 'sara-terms',
};
export const NZ_ROUTES_MAPPING = {
  [ROUTES.HOME]: 'sara-home-nz',
  [ROUTES.CATEGORIES]: 'sara-categories-nz',
  [ROUTES.CONTACTUS]: 'sara-contact-us',
  // [ROUTES.ENTER]: 'sara-enter-nz',
  [ROUTES.ENTRY_TIPS]: 'sara-entry-tips-nz',
  [ROUTES.EVENT]: 'sara-event-nz',
  [ROUTES.FAQS]: 'sara-faq',
  [ROUTES.WINNERS_FINALISTS]: 'sara-winners-finalists-nz',
  [ROUTES.WINNERS_FINALISTS_2021]: 'sara-winners-finalists-nz-2021',
  [ROUTES.WHY_ENTER]: 'sara-why-enter-nz',
  [ROUTES.TERMS]: 'sara-terms-nz',
};
