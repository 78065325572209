import type { ContainersFragment, GetPagesQuery } from '@seek/cmsu-cms-connect';
import { BannerRenderer } from '@seek/cmsu-components';
import { Box } from 'braid-design-system';
import React from 'react';
import { Outlet, useLocation } from 'react-router';

import {
  NZ_ROUTES_MAPPING,
  ROUTES_MAPPING,
  ROUTES_PAGES_MAPPING,
} from '../../../routes';
import { NotFound } from '../../pages';
import { Banner } from '../Banner/Banner';
import { Footer } from '../Footer/Footer';
import { Loading } from '../Loading/Loading';
import { useLocalized } from '../helpers/hooks/useLocalized';
import { Helm } from '../Helmet/Helmet';
import { useGetPage } from 'src/client/hooks/useGetPage';
import { BraidWrapper } from '../BraidWrapper/BraidWrapper';
import { SaraHeader } from '../SaraHeader/SaraHeader';
type Page = Pick<GetPagesQuery['pages'][number], 'title'>;

export type Containers = {
  containers: ContainersFragment[];
  slug: string;
  page: Page;
};

const PageLayout = () => {
  const { pathname } = useLocation();

  const slug = useLocalized({
    'en-AU': ROUTES_MAPPING[pathname],
    'en-NZ': NZ_ROUTES_MAPPING[pathname],
  });

  const { loading, errors, page } = useGetPage(slug);

  if (loading) {
    return <Loading />;
  }

  if (errors.length > 0 || !page) {
    return <NotFound />;
  }

  const context: Containers = {
    containers: page.containers,
    slug,
    page: { title: page.title },
  };

  return (
    <BraidWrapper theme={page.relatedPageSection?.theme || undefined}>
      <Box style={{ backgroundColor: '#EB008B0D' }}>
        <Helm title={ROUTES_PAGES_MAPPING[pathname]} />
        <SaraHeader />
        {page.banner && (
          <BannerRenderer
            banner={page.banner}
            renderers={{
              CBannerPrimary: (props) => (
                <Banner __typename="CBannerPrimary" {...props} />
              ),
            }}
          />
        )}
        <Box
          paddingBottom="xxlarge"
          marginTop={Boolean(page.banner) ? 'xlarge' : 'none'}
        >
          <Outlet context={context} />
        </Box>
        <Footer />
      </Box>
    </BraidWrapper>
  );
};

export { PageLayout };
