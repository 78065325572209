import React, { type ReactNode } from 'react';
import { Box } from 'braid-design-system';

import type { ContainersFragment } from '@seek/cmsu-cms-connect';

type Props = Pick<ContainersFragment, 'htmlId' | 'box'> & {
  children: ReactNode;
};

const SaraContainer = ({ htmlId, box, children }: Props) => (
  <Box
    id={htmlId as string | undefined}
    textAlign={box?.textAlign || 'left'}
    position="relative"
  >
    {children}
  </Box>
);
export { SaraContainer };
