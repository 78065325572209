import {
  type BlocksFragment,
  type BoxFragment,
  type BrightcoveVideo,
  type CBox,
  type CImageBlock,
  CTextAlign,
  type CTile,
  type TileFragment,
} from '@seek/cmsu-cms-connect';
import {
  AccordionGroup,
  BlockItemRenderer,
  type BlockItemRenderers,
} from '@seek/cmsu-components';
import { Box } from 'braid-design-system';
import React, { useMemo } from 'react';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { CardGroup } from '../CardGroup/CardGroup';
import { ImageBlockGroup } from '../ImageBlockGroup/ImageBlockGroup';
import { ImageContentBlockWrapper } from '../ImageContentBlockWrapper/ImageContentBlockWrapper';
import { ImageGallery } from '../ImageGallery/ImageGallery';
import { SaraBlock, type SaraBlockProps } from '../SaraBlock/SaraBlock';
import { VideoBlock } from '../VideoBlock/VideoBlock';
import { useConfig } from '../helpers/hooks/useConfig';
import { useOutletContext } from 'react-router-dom';
import type { Containers } from '../PageLayout/PageLayout';
import {
  type NodeRendererType,
  renderers,
  RichText,
} from '@seek/cmsu-rich-text';
import { DialogGroup } from '../DialogGroup/DialogGroup';
import type { BraidBoxProps } from '../helpers/types';

type Props = {
  block: BlocksFragment['items'][number];
  box?: BoxFragment | null;
  [x: string]: any;
  saraBlockHeading: SaraBlockProps['heading'];
  richTextRenderers?: NodeRendererType;
  containerTile?: TileFragment;
  paddingTop?: BraidBoxProps['padding'];
};

export const PageBlock = ({
  block,
  box,
  saraBlockHeading,
  richTextRenderers,
  containerTile,
  paddingTop,
  ...rest
}: Props) => {
  const { fullURL } = useConfig();
  const { page } = useOutletContext<Containers>();

  const blockRenderers = useMemo<BlockItemRenderers>(
    () => ({
      CImageBlockGroup: ({ items, tile }) => (
        <SaraBlock
          heading={saraBlockHeading}
          headingAlignment="center"
          {...{ richTextRenderers }}
          {...rest}
        >
          <ImageBlockGroup
            items={items as CImageBlock[]}
            tile={tile as CTile}
          />
        </SaraBlock>
      ),
      CActionGroup: (actionGroup) => (
        // Padding top was added to fit the content
        <SaraBlock paddingBottom="gutter" paddingTop="gutter">
          <ActionGroup {...actionGroup} {...rest} {...{ richTextRenderers }} />
        </SaraBlock>
      ),
      CParagraph: ({ Paragraph_text }) => (
        <SaraBlock paddingBottom="xxsmall" heading={saraBlockHeading}>
          <Box>
            <RichText
              content={Paragraph_text?.raw}
              renderers={{
                ...renderers,
                ...richTextRenderers,
              }}
            />
          </Box>
        </SaraBlock>
      ),
      CCardGroup: (props) => (
        <SaraBlock heading={saraBlockHeading} headingAlignment="left" {...rest}>
          <CardGroup {...props} {...rest} {...{ richTextRenderers }} />
        </SaraBlock>
      ),
      CGallery: (props) => (
        <SaraBlock>
          <ImageGallery {...props} />
        </SaraBlock>
      ),
      SaraImageContentBlock: ({
        imageContentHeading,
        image,
        alignmentStyle,
        blocks,
        richDescription,
      }) => (
        <SaraBlock
          box={box}
          heading={imageContentHeading || undefined}
          headingAlignment={CTextAlign.Left}
          image={image?.url}
          imageAlignment={alignmentStyle}
          {...rest}
        >
          <ImageContentBlockWrapper
            tile={containerTile}
            blocks={blocks}
            richDescription={richDescription}
            {...{ richTextRenderers }}
            {...rest}
          />
        </SaraBlock>
      ),
      AccordionGroup: (props) => (
        <SaraBlock>
          <AccordionGroup {...props} />
        </SaraBlock>
      ),
      Video: ({ source, box: videoBox }) => (
        <SaraBlock heading={saraBlockHeading}>
          <VideoBlock
            source={source as BrightcoveVideo}
            box={videoBox as CBox}
          />
        </SaraBlock>
      ),
      DialogGroup: ({ dialogItems, dialogBlockHeading }) => (
        <SaraBlock
          heading={dialogBlockHeading as string}
          paddingTop={paddingTop}
        >
          <DialogGroup
            dialogItems={dialogItems}
            tile={containerTile}
            {...rest}
          />
        </SaraBlock>
      ),
    }),
    [box, containerTile, paddingTop, rest, richTextRenderers, saraBlockHeading],
  );

  return (
    <BlockItemRenderer
      block={block}
      renderers={blockRenderers}
      additionalData={{
        fullURL,
        page,
      }}
    />
  );
};
