// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Show more': () => 'Show more' | '[Šḩöööŵ m̂öööřẽẽẽ]';
    'Show less': () => 'Show less' | '[Šḩöööŵ ƚẽẽẽšš]';
  }
>({
  en: createLanguage({ 'Show more': 'Show more', 'Show less': 'Show less' }),
  th: createLanguage({ 'Show more': 'Show more', 'Show less': 'Show less' }),
  id: createLanguage({ 'Show more': 'Show more', 'Show less': 'Show less' }),
  zh: createLanguage({ 'Show more': 'Show more', 'Show less': 'Show less' }),
  'en-AU': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-MY': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-NZ': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-PH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-SG': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'th-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'id-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'zh-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
  }),
  'en-PSEUDO': createLanguage({
    'Show more': '[Šḩöööŵ m̂öööřẽẽẽ]',
    'Show less': '[Šḩöööŵ ƚẽẽẽšš]',
  }),
});

export default translations;
