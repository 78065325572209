import React, { useState, useEffect } from 'react';
import escapeHtml from 'escape-html';
import type { IFrameProps } from '@graphcms/rich-text-types';
import { Box } from 'braid-design-system';

interface IframeMessage {
  type: string;
  height: number; // Expecting a numeric value for height
}

const isVideoUrl = (url: string): boolean =>
  url.includes('youtube') || url.includes('brightcove');

export function IFrame({ height, width, url }: Partial<IFrameProps>) {
  const [iframeHeight, setIframeHeight] = useState(`${height}px`);
  useEffect(() => {
    const handleResizeMessage = (event: MessageEvent<IframeMessage>) => {
      const message = event.data;
      if (message.type === 'resize' && typeof message.height === 'number') {
        setIframeHeight(`${message.height}px`);
      }
    };
    window.addEventListener('message', handleResizeMessage);
    return () => {
      window.removeEventListener('message', handleResizeMessage);
    };
  }, []);
  if (!url) {
    return <></>;
  }
  if (!isVideoUrl(url)) {
    return (
      <Box marginY={'medium'}>
        <iframe
          role="iframe"
          allowFullScreen={true}
          style={{ border: 'none' }}
          width="100%"
          height={iframeHeight}
          src={escapeHtml(url)}
        />
      </Box>
    );
  }
  return (
    <Box
      data-testid="iframe-box"
      marginY={'medium'}
      style={{
        position: 'relative',
        width: '100%',
        // 16:9 Aspect Ratio
        paddingBottom: `56.25%`,
      }}
    >
      <iframe
        src={escapeHtml(url)}
        height={height}
        width={width}
        allow="encrypted-media"
        allowFullScreen={true}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    </Box>
  );
}
