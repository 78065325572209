import {
  Locale,
  type GetPagesQuery,
  type Stage,
  getPageWithEntities,
} from '@seek/cmsu-cms-connect';
import { useCallback, useEffect, useState } from 'react';
import { logHandler } from '../components/modules/helpers/utils';
import {
  type ApolloError,
  useApolloClient,
  type ApolloClient,
  type NormalizedCacheObject,
} from '@apollo/client';
type PageData = GetPagesQuery['pages'][0];
/**
 * The `useGetPage` function is a custom hook that fetches page data based on a given
 * slug and returns the loading state, any errors encountered, and the page data itself.
 * @param {string} slug - The `slug` parameter is a string that represents the unique identifier for a
 * specific page. It is used to fetch the data for that page.
 * @returns The `useGetPage` function returns an object with three properties: `loading`, `errors`, and
 * `page`.
 */
const useGetPage = (
  slug: string,
): {
  loading: boolean;
  errors: ApolloError[];
  page: PageData | undefined;
} => {
  const [page, setPage] = useState<PageData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const client = useApolloClient();
  const [pageError, setPageError] = useState<ApolloError[] | undefined>(
    undefined,
  );
  const getData = useCallback(async () => {
    try {
      const pageDetails = await getPageWithEntities({
        client: client as ApolloClient<NormalizedCacheObject>,
        variables: {
          where: {
            slug,
          },
          locale: [Locale.Default],
          stage: 'PUBLISHED' as Stage.Published,
        },
      });
      if (!pageDetails) {
        setLoading(false);
        return;
      }
      setPage(pageDetails as PageData);
      setLoading(false);
    } catch (e) {
      logHandler('Error getting page data', e);
      setPageError(e as ApolloError[]);
      setLoading(false);
    }
  }, [client, slug]);
  useEffect(() => {
    if (slug && !page) {
      getData();
    }
  }, [slug, getData, page]);
  return { loading, errors: pageError || [], page };
};
export { useGetPage };
