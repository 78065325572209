import type { ButtonLinkFragment } from '@seek/cmsu-cms-connect';
import { Box, Button, ButtonLink } from 'braid-design-system';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import { ConditonalWrapper } from '../ConditionalWrapper/ConditionalWrapper ';
import { SaraButton } from '../SaraButton/SaraButton';

type ActionProps = Pick<
  ButtonLinkFragment,
  'link' | 'text' | 'tone' | 'variant'
> & {
  hashlink?: boolean;
};

const Action = ({
  text,
  tone,
  hashlink = false,
  link,
  variant,
}: ActionProps) => {
  const href = (
    link as { to: { __typename: 'ExternalUrl'; externalUrl: string } }
  )?.to.externalUrl;

  return (
    <Box textAlign="center">
      <ConditonalWrapper
        condition={hashlink}
        wrapper={(children) => (
          <HashLink to={href} smooth style={{ textDecoration: 'none' }}>
            {children}
          </HashLink>
        )}
      >
        <SaraButton
          component={!hashlink ? ButtonLink : Button}
          tone={tone || 'brandAccent'}
          href={href}
          size="standard"
          variant={variant || 'solid'}
        >
          <Box paddingX="large">{text}</Box>
        </SaraButton>
      </ConditonalWrapper>
    </Box>
  );
};

export { Action, type ActionProps };
