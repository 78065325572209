import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import React from 'react';
import { Box, Stack, Text } from 'braid-design-system';
import * as styles from './Card.css';

type Props = Pick<CardPrimaryFragment, 'heading' | 'paragraph'>;

const StandardCard = ({ heading, paragraph }: Props) => (
  <Box position="relative" height="full">
    <Stack space="small">
      <Text weight="strong" tone="brandAccent">
        {heading?.text}
      </Text>
      {Boolean(paragraph) && (
        <RichText content={paragraph?.Paragraph_text?.raw} />
      )}
    </Stack>
    <Box position="absolute" width="full" className={styles.bottomBorder} />
  </Box>
);

export { StandardCard };
