import React from 'react';

import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';

import type { ArrayElement } from '../helpers/types';

import { BorderedDescription } from './BorderedDescription';
import { BorderedTitleDescHeading } from './BorderedTitleDescHeading';
import { BorderedTitleHeading } from './BorderedTitleHeading';
import { BorderedTitleNumberedDesc } from './BorderedTitleNumberedDesc';
import { ArticleHeading } from './ArticleHeading';
export type HeadingType =
  | 'standard'
  | 'borderedTitleDesc'
  | 'borderedTitle'
  | 'article'
  | 'borderedDescription'
  | 'borderedTitleNumberedDesc'
  | 'categoryHeading';

type HeadingProps = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
> & { headingType?: HeadingType };

const Heading = ({
  title,
  richTextDescription,
  headingType,
  ...rest
}: HeadingProps) => {
  switch (headingType) {
    case 'borderedTitleDesc': {
      return (
        <BorderedTitleDescHeading
          title={title}
          richTextDescription={richTextDescription}
          {...rest}
        />
      );
    }
    case 'borderedTitle':
      return (
        <BorderedTitleHeading
          title={title}
          richTextDescription={richTextDescription}
          {...rest}
        />
      );
    case 'borderedTitleNumberedDesc':
      return (
        <BorderedTitleNumberedDesc
          title={title}
          richTextDescription={richTextDescription}
          {...rest}
        />
      );
    case 'article':
      return (
        <ArticleHeading
          richTextDescription={richTextDescription}
          title={title}
          {...rest}
        />
      );
    case 'borderedDescription':
      return (
        <BorderedDescription
          title={title}
          richTextDescription={richTextDescription}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export { Heading, type HeadingProps };
