import type { RichTextContent } from '@graphcms/rich-text-types';
import { RichText } from '@seek/cmsu-rich-text';
import {
  Button,
  Dialog as BraidDialog,
  IconInfo,
  Inline,
  Box,
} from 'braid-design-system';
import React, { useState } from 'react';

type Props = {
  dialogDescription: { raw: RichTextContent };
  dialogTitle: string | null | undefined;
};

const DialogModal = ({ dialogDescription, dialogTitle }: Props) => {
  const [showDialog, toggleDialog] = useState(false);
  return (
    <Box paddingBottom="small">
      <Inline space="none" align="left">
        <Button onClick={() => toggleDialog(true)} variant="transparent">
          <IconInfo /> View criteria
        </Button>
      </Inline>
      <BraidDialog
        id="dialog-group-dialog-braid-dialog"
        title={dialogTitle || ''}
        open={showDialog}
        onClose={() => toggleDialog(false)}
      >
        <RichText content={dialogDescription.raw} />
      </BraidDialog>
    </Box>
  );
};
export { DialogModal };
