import React from 'react';

import type { SaraHeadingGroupFragment } from '@seek/cmsu-cms-connect';
import {
  type NodeRendererType,
  renderers,
  RichText,
} from '@seek/cmsu-rich-text';
import { Stack, Text } from 'braid-design-system';

import type { ArrayElement } from '../helpers/types';

type Props = Pick<
  ArrayElement<SaraHeadingGroupFragment['headingGroupItems']>,
  'richTextDescription' | 'title'
> & { richTextRenderers?: NodeRendererType };

const ArticleHeading = ({
  richTextDescription,
  title,
  richTextRenderers,
}: Props) => (
  <Stack space="large">
    <Text weight="strong">{title}</Text>
    <RichText
      content={richTextDescription?.raw}
      renderers={{
        ...renderers,
        ...richTextRenderers,
      }}
    />
  </Stack>
);

export { ArticleHeading };
