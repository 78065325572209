import {
  getLocale,
  getSiteNameFromHostname,
  type Language,
  type Locale,
} from '@seek/melways-sites';
import { useContext, createContext } from 'react';

import { ROUTES } from 'src/client/routes';
import type {
  Environment,
  EmployerSiteName,
  CandidateSiteName,
} from '../types';

export type ConfigStateContext = {
  site: EmployerSiteName | CandidateSiteName;
  locale: Locale;
  language: Language;
  environment: Environment;
  basename: string;
  fullURL: string;
};

type ConfigProviderProps = {
  children: React.ReactNode;
  config: ConfigStateContext;
};

const ConfigContext = createContext<ConfigStateContext | undefined>(undefined);

const ConfigProvider = ({ children, config }: ConfigProviderProps) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

/**
 * Gets the config context.
 */
const useConfig = () => {
  const context = useContext(ConfigContext);
  /**
   * If this is used outside a child of ConfigProvider, throw an error.
   */
  if (context === undefined) {
    throw new Error('useConfigs must be used within a ConfigProvider');
  }

  return context;
};

export { ConfigProvider, useConfig };

/**
 * Creates a config that gets passed to the frontend via the global window variable.
 */
export function createConfig(
  hostname: string,
  originalUrl: string,
  xForwardedHost: string,
  fullURL: string,
): ConfigStateContext {
  const site = getSiteName(xForwardedHost || hostname);
  const language = getLanguage(originalUrl);
  const locale = getLocale(site, language) as Locale;
  const basename = `${
    language !== 'en'
      ? `/${language}/${ROUTES.BASE_NAME}`
      : `/${ROUTES.BASE_NAME}`
  }`;

  return {
    environment: process.env.NODE_ENV || ('production' as Environment),
    site,
    language,
    locale,
    basename,
    fullURL,
  };
}

const getSiteName = (hostname: string) => {
  if (hostname === 'localhost') {
    // to handle testing on pipeline where hostname is localhost
    return 'candidate-seek-au' as CandidateSiteName;
  }
  return getSiteNameFromHostname(
    hostname.replace('.local', ''),
  ) as CandidateSiteName;
};

const getLanguage = (originalUrl: string) => {
  // Check if the path starts with a 2-letter language code eg., /th/
  if (originalUrl.split('/')[1].length === 2) {
    return originalUrl.split('/')[1] as Language;
  }
  return 'en' as Language;
};
