import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import React from 'react';

type Props = Pick<CardPrimaryFragment, 'heading' | 'paragraph'>;

const ComingSoonCard = ({ paragraph }: Props) => (
  <RichText content={paragraph?.Paragraph_text?.raw} />
);

export { ComingSoonCard };
