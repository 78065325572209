import { RichText } from '@seek/cmsu-rich-text';
import { Box, Disclosure, Stack, Text } from 'braid-design-system';
import React from 'react';
import type { DialogFragment } from '@seek/cmsu-cms-connect';

type Props = Pick<
  DialogFragment,
  'dialogDescription' | 'dialogHeading' | 'initialDescription' | 'image'
>;

const DisclosureDialog = ({
  dialogHeading,
  initialDescription,
  dialogDescription,
  image,
}: Props) => (
  <Box paddingBottom="xsmall">
    <Stack space="gutter">
      <Box width="full" display="flex" justifyContent="center">
        <Box
          style={{
            backgroundImage: `url(${image?.url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%',
            width: `180px`,
            height: `180px`,
            borderRadius: '50%',
            border: '4px solid rgb(253, 237, 247)',
          }}
        />
      </Box>

      <Text weight="strong" tone="neutral" size="large">
        {dialogHeading}
      </Text>
      {Boolean(initialDescription) && (
        <Box paddingBottom="small">
          <RichText content={initialDescription?.raw} />
        </Box>
      )}
      {Boolean(dialogDescription) && (
        <Disclosure
          expandLabel="show more"
          collapseLabel="show less"
          id="judge-show-more"
        >
          <Box paddingTop="small">
            <RichText content={dialogDescription?.raw} />
          </Box>
        </Disclosure>
      )}
    </Stack>
  </Box>
);

export { DisclosureDialog };
