import { RichText } from '@seek/cmsu-rich-text';
import { Box, Stack, Text } from 'braid-design-system';
import React from 'react';
import type { DialogFragment } from '@seek/cmsu-cms-connect';

import { DialogModal } from './DialogModal';

import * as styles from './Dialog.css';

type Props = Pick<
  DialogFragment,
  'dialogDescription' | 'dialogHeading' | 'initialDescription' | 'image'
>;

const StandardDialog = ({
  dialogHeading,
  initialDescription,
  dialogDescription,
}: Props) => (
  <Box
    position="relative"
    height="full"
    paddingBottom="xxlarge"
    paddingTop="medium"
    marginX="medium"
  >
    <Stack space="large">
      <Text weight="strong" tone="brandAccent" size="large">
        {dialogHeading}
      </Text>
      {Boolean(initialDescription) && (
        <RichText content={initialDescription?.raw} />
      )}
    </Stack>
    <Box
      position="absolute"
      width="full"
      className={styles.bottomBorder}
      paddingBottom="small"
    >
      {dialogDescription && (
        <Box>
          <DialogModal
            dialogDescription={dialogDescription}
            dialogTitle={dialogHeading}
          />
        </Box>
      )}
    </Box>
  </Box>
);

export { StandardDialog };
